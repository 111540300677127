// CreateUser.js
import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import './CreateUser.css';

const CreateUser = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [selectedDomain, setSelectedDomain] = useState('');
  const [authorizedDomains, setAuthorizedDomains] = useState([]);
  const [error, setError] = useState('');

  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (currentUser) {
      fetchAuthorizedDomains();
    }
  }, [currentUser]);

  const handleErrorResponse = (response) => {
    if (response.status === 401) {
      setError('Unauthorized access. Please log in again.');
    } else if (response.status === 403) {
      setError(
        'Forbidden access. You do not have permission to perform this action.'
      );
    } else {
      setError('An error occurred. Please try again later.');
    }
  };

  const fetchWithAuth = async (url, options = {}) => {
    const token = await currentUser.getIdToken();
    const defaultHeaders = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    const response = await fetch(url, {
      ...options,
      headers: {
        ...defaultHeaders,
        ...options.headers,
      },
    });
    if (!response.ok) {
      handleErrorResponse(response);
      throw new Error('Network response was not ok');
    }
    return response.json();
  };

  const fetchAuthorizedDomains = async () => {
    try {
      const data = await fetchWithAuth(
        `https://project-download-upload.wn.r.appspot.com/authorized-domains?email=${currentUser.email}`
      );
      setAuthorizedDomains(data);
      if (data.length > 0) {
        setSelectedDomain(data[0]);
      }
    } catch (error) {
      console.error('Error fetching authorized domains:', error);
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      const emailWithDomain = `${email}@${selectedDomain}`;

      await fetchWithAuth(
        'https://project-download-upload.wn.r.appspot.com/createUser',
        {
          method: 'POST',
          body: JSON.stringify({
            email: emailWithDomain,
            displayName: `${firstName} ${lastName}`,
          }),
        }
      );

      setError('');
      setEmail('');
      setFirstName('');
      setLastName('');

      if (onClose) {
        onClose();
      }
    } catch (error) {
      setError('Failed to create user: ' + error.message);
    }
  };

  const handleCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="create-user">
      <form onSubmit={handleCreateUser} autoComplete="off">
        <div className="create-user-input-group">
          <div className="create-user-name-container">
            <div className="create-user-name-input">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                required
                autoComplete="off"
              />
            </div>
            <div className="create-user-name-input">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                required
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <div className="create-user-input-group">
          <label htmlFor="email">Email</label>
          <div className="create-user-email-container">
            <input
              type="text"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
              autoComplete="off"
            />
            <select
              id="domain"
              name="domain"
              value={selectedDomain}
              onChange={(e) => setSelectedDomain(e.target.value)}
              required
            >
              {authorizedDomains.map((domain) => (
                <option key={domain} value={domain}>
                  {domain}
                </option>
              ))}
            </select>
          </div>
        </div>
        {error && <p className="create-user-error-message">{error}</p>}
        <div className="create-user-button-group">
          <button type="submit" className="create-user-submit-button">
            Create User
          </button>
          <button
            type="button"
            className="create-user-cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateUser;
