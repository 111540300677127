// src/components/DeviceManagement/RegisterDevice.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import QrScanner from 'react-qr-scanner';
import Modal from 'react-modal';
import './RegisterDeviceModal.css'; // Ensure this CSS file exists
import API_BASE_URL from '../config'; // Adjust the path based on your project structure

// Set the app element for accessibility (required for react-modal)
Modal.setAppElement('#root'); // Replace '#root' with your app's root element ID if different

const RegisterDevice = ({ isOpen, onRequestClose }) => {
  const [deviceId, setDeviceId] = useState('');
  const [screenSn, setScreenSn] = useState('');
  const [showScanner, setShowScanner] = useState(false);
  const [cameraDevices, setCameraDevices] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState('');

  useEffect(() => {
    if (showScanner) {
      getCameraDevices();
    }
  }, [showScanner]);

  // Fetch available camera devices
  const getCameraDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');
      setCameraDevices(videoDevices);
      if (videoDevices.length > 0) {
        setSelectedCamera(videoDevices[0].deviceId); // Set the first camera as default
      }
    } catch (error) {
      console.error('Error fetching camera devices:', error);
      alert('Unable to access camera devices.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const deviceIdTrimmed = deviceId.trim();
    const screenSnTrimmed = screenSn.trim();

    if (!deviceIdTrimmed || !screenSnTrimmed) {
      alert('Please enter both Device ID and Screen SN.');
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/check-device`, {
        deviceId: deviceIdTrimmed,
        screenSn: screenSnTrimmed,
      });
      console.log('Response:', response.data);
      alert('Device registered successfully!');
      // Optionally, reset form fields
      setDeviceId('');
      setScreenSn('');
      onRequestClose(); // Close the modal after successful registration
    } catch (error) {
      console.error('Error checking Device ID:', error);
      alert('Failed to register device. Please try again.');
    }
  };

  const handleScan = (data) => {
    if (data && data.text) {
      console.log('QR Code Data:', data.text); // For debugging
      setDeviceId(data.text);
      setShowScanner(false);
    }
  };

  const handleError = (err) => {
    console.error('QR Scanner Error:', err);
    alert('Error accessing the camera. Please try again.');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Register Device Modal"
      className="register-device-modal"
      overlayClassName="register-device-modal-overlay"
    >
      <div className="register-device-container">
        <button className="modal-close-button" onClick={onRequestClose}>
          &times;
        </button>
        <h2>Register New Device</h2>
        <form onSubmit={handleSubmit} className="register-device-form">
          <div className="form-group">
            <label htmlFor="deviceId">Device ID:</label>
            <input
              type="text"
              id="deviceId"
              value={deviceId}
              onChange={(e) => setDeviceId(e.target.value)}
              required
            />
            <button
              type="button"
              className="scan-button"
              onClick={() => setShowScanner(true)}
            >
              Scan ID
            </button>
          </div>
          <div className="form-group">
            <label htmlFor="screenSn">Screen SN:</label>
            <input
              type="text"
              id="screenSn"
              value={screenSn}
              onChange={(e) => setScreenSn(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="submit-button">Submit</button>
        </form>

        {/* QR Code Scanner Modal */}
        {showScanner && (
          <div className="qr-scanner-modal">
            <div className="qr-scanner-content">
              <h3>Scan QR Code</h3>
              {/* Dropdown menu for selecting camera */}
              <select
                className="camera-selector"
                value={selectedCamera}
                onChange={(e) => setSelectedCamera(e.target.value)}
              >
                {cameraDevices.map((device, index) => (
                  <option key={index} value={device.deviceId}>
                    {device.label || `Camera ${index + 1}`}
                  </option>
                ))}
              </select>

              {/* QR Scanner Component */}
              <QrScanner
                delay={300}
                style={{ width: '100%' }}
                onError={handleError}
                onScan={handleScan}
                constraints={{
                  video: { deviceId: selectedCamera ? { exact: selectedCamera } : undefined },
                }}
              />

              <button onClick={() => setShowScanner(false)} className="qr-scanner-close-button">
                &times;
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default RegisterDevice;
