// src/components/ConfigureDeviceModal.js

import React from 'react';
import Modal from 'react-modal'; // You may need to install react-modal
import ConfigureDeviceForm from './ConfigureDeviceForm';
import './ConfigureDeviceModal.css'; // Create CSS for the modal styling

// Set the app element for accessibility (if you're using react-modal)
Modal.setAppElement('#root'); // Replace '#root' with your app's root element ID

const ConfigureDeviceModal = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      contentLabel="Configure Device Modal"
      className="configure-device-modal"
      overlayClassName="configure-device-modal-overlay"
    >
      <ConfigureDeviceForm {...props} />
    </Modal>
  );
};

export default ConfigureDeviceModal;
