// ButtonsAndSearchBar.js
import React from 'react';

const ButtonsAndSearchBar = ({
  handleImagesClick,
  handleVideosClick,
  activeButton,
  searchTerm,
  setSearchTerm,
  handleUploadClick,
  handleCommit,
  isOrderChanged,
}) => {
  return (
    <div className="content-manager-buttons">
      <button
        onClick={handleImagesClick}
        className={activeButton === 'images' ? 'active' : ''}
        aria-pressed={activeButton === 'images'}
        aria-label="Filter images"
      >
        Images
      </button>
      <button
        onClick={handleVideosClick}
        className={activeButton === 'videos' ? 'active' : ''}
        aria-pressed={activeButton === 'videos'}
        aria-label="Filter videos"
      >
        Videos
      </button>
      <input
        type="text"
        className="search-input"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search content..."
        aria-label="Search content"
      />
      <button onClick={handleUploadClick} className="upload-button" aria-label="Upload media">
        Upload
      </button>
      {/* Commit Button with Underglow Effect */}
      <button
        className={`commit-button ${isOrderChanged ? 'underglow' : ''}`}
        onClick={handleCommit}
        aria-label="Commit changes"
      >
        Commit
      </button>
    </div>
  );
};

export default ButtonsAndSearchBar;
