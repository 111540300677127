// CollapsibleSidebar.js
import React from 'react';
import CollapsibleSection from '../CollapsibleSection';

const CollapsibleSidebar = ({
  isContainerCollapsed,
  setIsContainerCollapsed,
  isPropertyCollapsed,
  setIsPropertyCollapsed,
  selectedProperty,
  handlePropertyChange,
  properties,
  isFloorCollapsed,
  setIsFloorCollapsed,
  selectedFloor,
  handleFloorChange,
  floors,
  isPitCollapsed,
  setIsPitCollapsed,
  selectedPit,
  handlePitChange,
  pits,
  selectedTable,
  handleTableChange,
  tables,
  isFolderCollapsed,
  setIsFolderCollapsed,
  selectedFolder,
  handleFolderClick,
  folders,
}) => {
  return (
    <div className={`collapsible-section-container ${isContainerCollapsed ? 'collapsed' : ''}`}>
      <button
        className="toggle-container-button"
        onClick={() => setIsContainerCollapsed(!isContainerCollapsed)}
        aria-label={isContainerCollapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}
      >
        {isContainerCollapsed ? '>' : '<'}
      </button>

      {!isContainerCollapsed && (
        <div className="collapsible-sections-wrapper">
          {/* Property Section */}
          <CollapsibleSection
            title="Property"
            isOpen={!isPropertyCollapsed}
            toggleOpen={() => setIsPropertyCollapsed(!isPropertyCollapsed)}
            selectedOption={selectedProperty}
          >
            <ul className="option-list">
              {properties.map((property, index) => (
                <li
                  key={index}
                  className={`option-item ${
                    selectedProperty === property.property_name ? 'selected' : ''
                  }`}
                  onClick={() => handlePropertyChange(property.property_name)}
                >
                  {property.property_name}
                </li>
              ))}
            </ul>
          </CollapsibleSection>

          {/* Floor Section */}
          <CollapsibleSection
            title="Floor"
            isOpen={!isFloorCollapsed}
            toggleOpen={() => setIsFloorCollapsed(!isFloorCollapsed)}
            selectedOption={selectedFloor}
          >
            <ul className="option-list">
              {floors.map((floor, index) => (
                <li
                  key={index}
                  className={`option-item ${
                    selectedFloor === floor.floor_type ? 'selected' : ''
                  }`}
                  onClick={() => handleFloorChange(floor.floor_type)}
                >
                  {floor.floor_type}
                </li>
              ))}
            </ul>
          </CollapsibleSection>

          {/* Pit Section */}
          <CollapsibleSection
            title="Pit"
            isOpen={!isPitCollapsed}
            toggleOpen={() => setIsPitCollapsed(!isPitCollapsed)}
            selectedOption={selectedPit}
          >
            <ul className="option-list">
              {pits.map((pit, index) => (
                <li
                  key={index}
                  className={`option-item ${
                    selectedPit === pit.floor_name ? 'selected' : ''
                  }`}
                  onClick={() => handlePitChange(pit.floor_name)}
                >
                  {pit.floor_name}
                </li>
              ))}
            </ul>
          </CollapsibleSection>

          {/* Table Section - Always Visible */}
          <div className="collapsible-vertical-section table-section">
            <h3 className="section-title">Table</h3>
            <ul className="option-list">
              {tables.map((table, index) => (
                <li
                  key={index}
                  className={`option-item ${
                    selectedTable === table.table_name ? 'selected' : ''
                  }`}
                  onClick={() => handleTableChange(table)}
                >
                  {table.table_name}
                </li>
              ))}
            </ul>
          </div>

          {/* Spacer Div to Push Folders to Bottom */}
          <div className="spacer"></div>

          {/* Folders Section */}
          {selectedTable && (
            <div className="folders-section">
              <CollapsibleSection
                title="Folders"
                isOpen={!isFolderCollapsed}
                toggleOpen={() => setIsFolderCollapsed(!isFolderCollapsed)}
                selectedOption={selectedFolder}
              >
                <div className="folders-button-container">
                  {folders.map((folder, index) => (
                    <button
                      key={index}
                      className={`folder-button ${
                        selectedFolder === folder ? 'selected' : ''
                      }`}
                      onClick={() => handleFolderClick(folder)}
                      aria-pressed={selectedFolder === folder}
                      aria-label={`Filter by folder ${folder}`}
                    >
                      {folder}
                    </button>
                  ))}
                  {folders.length === 0 && (
                    <p className="no-folders-message">No folders available.</p>
                  )}
                </div>
              </CollapsibleSection>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CollapsibleSidebar;
