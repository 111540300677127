// src/UploadMedia.js
import React, { useState, useEffect } from 'react';
import './UploadMedia.css';
import axiosInstance from '../axiosInstance';
import uploadIcon from '../content/Drag_files_or_click_upload.svg';
// Removed FontAwesome imports for now

const UploadMedia = ({ email, setSubHeader }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isDragging, setIsDragging] = useState(false); // State to manage drag-and-drop visual feedback

    // Tagging state
    const [isTagging, setIsTagging] = useState(false);
    const [currentTagFileIndex, setCurrentTagFileIndex] = useState(0);
    const [tags, setTags] = useState({});

    const MAX_DISPLAYED_FILES = 4; // Max number of files to show directly

    useEffect(() => {
        setSubHeader("Upload Media");
    }, [setSubHeader]);

    const handleFileSelect = (event) => {
        if (event.target.files) {
            const files = Array.from(event.target.files);
            setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
        }
    };

    const handleFileDrop = (event) => {
        event.preventDefault();
        setIsDragging(false); // Reset the dragging state

        if (event.dataTransfer.files) {
            const files = Array.from(event.dataTransfer.files);
            setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true); // Set the dragging state
    };

    const handleDragLeave = () => {
        setIsDragging(false); // Reset the dragging state when the file leaves the drop area
    };

    const handleRemoveSelectedFile = (fileName) => {
        setSelectedFiles((prevFiles) => prevFiles.filter(file => file.name !== fileName));
        setTags((prevTags) => {
            const newTags = { ...prevTags };
            delete newTags[fileName];
            return newTags;
        });
    };

    // Handle file upload events
    const handleFileUpload = () => {
        if (selectedFiles.length === 0) return;

        // Initialize tagging state
        setIsTagging(true);
        const initialTags = {};
        selectedFiles.forEach(file => {
            initialTags[file.name] = []; // Initialize with no tags; friendlyName is handled separately
        });
        setTags(initialTags);
        setCurrentTagFileIndex(0);
    };

    // Tagging Handlers
    const handleBack = () => {
        if (currentTagFileIndex > 0) {
            setCurrentTagFileIndex(currentTagFileIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentTagFileIndex < selectedFiles.length - 1) {
            setCurrentTagFileIndex(currentTagFileIndex + 1);
        }
    };

    const handleCancel = () => {
        // Check if any files have no tags
        const anyUntagged = selectedFiles.some(file => tags[file.name].length === 0);
        if (anyUntagged) {
            const confirmProceed = window.confirm('Some files have no tags. Are you sure you want to proceed?');
            if (!confirmProceed) return;
        }
        // Cancel tagging
        setIsTagging(false);
        setTags({});
        setSelectedFiles([]);
    };

    const handleFinish = async () => {
        const anyUntagged = selectedFiles.some(file => tags[file.name].length === 0);
        if (anyUntagged) {
            const confirmProceed = window.confirm('Some files have no tags. Are you sure you want to proceed?');
            if (!confirmProceed) return;
        }

        try {
            for (const file of selectedFiles) {
                const fileTags = tags[file.name] || [];

                console.log(`Uploading ${file.name} with tags:`, fileTags);

                const formData = new FormData();
                formData.append('file', file);
                formData.append('tags', JSON.stringify(fileTags));

                const response = await axiosInstance.post('/upload-media', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                if (![200, 201].includes(response.status)) {
                    throw new Error(`Failed to upload ${file.name}: ${response.statusText}`);
                }

                console.log(`File ${file.name} uploaded successfully.`);
            }

            console.log('All files uploaded successfully with tags:', tags);
            setIsTagging(false);
            setTags({});
            setSelectedFiles([]);
        } catch (error) {
            console.error('Error during file upload:', error);
            alert(`Error during file upload: ${error.message}`);
        }
    };

    const handleAddTag = (fileName) => {
        setTags(prevTags => ({
            ...prevTags,
            [fileName]: [...prevTags[fileName], { key: '', value: '' }],
        }));
    };

    const handleTagChange = (fileName, index, field, value) => {
        setTags(prevTags => {
            const updatedTags = [...prevTags[fileName]];
            updatedTags[index][field] = value;
            return { ...prevTags, [fileName]: updatedTags };
        });
    };

    const handleRemoveTag = (fileName, index) => {
        setTags(prevTags => {
            const updatedTags = [...prevTags[fileName]];
            updatedTags.splice(index, 1);
            return { ...prevTags, [fileName]: updatedTags };
        });
    };

    // Image preview URL management
    const [previewUrl, setPreviewUrl] = useState(null);

    useEffect(() => {
        if (isTagging && selectedFiles[currentTagFileIndex]) {
            const file = selectedFiles[currentTagFileIndex];
            if (file.type.startsWith('image/')) {
                const url = URL.createObjectURL(file);
                setPreviewUrl(url);
                return () => URL.revokeObjectURL(url);
            } else {
                setPreviewUrl(null);
            }
        }
    }, [isTagging, currentTagFileIndex, selectedFiles]);

    // New States for Editable Heading
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [fileBaseName, setFileBaseName] = useState('');
    const [fileExtension, setFileExtension] = useState('');

    // Function to split filename into base name and extension
    const splitFileName = (filename) => {
        const lastDotIndex = filename.lastIndexOf('.');
        if (lastDotIndex === -1) {
            return { baseName: filename, extension: '' };
        }
        const baseName = filename.substring(0, lastDotIndex);
        const extension = filename.substring(lastDotIndex);
        return { baseName, extension };
    };

    // Update fileBaseName and fileExtension when current file changes
    useEffect(() => {
        if (isTagging && selectedFiles[currentTagFileIndex]) {
            const { baseName, extension } = splitFileName(selectedFiles[currentTagFileIndex].name);
            console.log('Setting fileBaseName to:', baseName);
            console.log('Setting fileExtension to:', extension);
            setFileBaseName(baseName);
            setFileExtension(extension);
        }
    }, [isTagging, currentTagFileIndex, selectedFiles]);

    const displayedFiles = selectedFiles.slice(0, MAX_DISPLAYED_FILES);
    const remainingFilesCount = selectedFiles.length - MAX_DISPLAYED_FILES;

    if (isTagging) {
        const currentFile = selectedFiles[currentTagFileIndex];
        const currentTags = tags[currentFile.name] || [];

        return (
            <div className="tagging-page">
                <div className="tagging-left">
                    {previewUrl ? (
                        <img src={previewUrl} alt={currentFile.name} className="tagging-preview-image" />
                    ) : (
                        <div className="tagging-preview-placeholder">No Preview Available</div>
                    )}
                    <div className="tagging-navigation">
                        <button onClick={handleBack} disabled={currentTagFileIndex === 0} className="nav-button">
                            &#8592; Back
                        </button>
                        <button onClick={handleNext} disabled={currentTagFileIndex === selectedFiles.length - 1} className="nav-button">
                            Next &#8594;
                        </button>
                    </div>
                </div>
                <div className="tagging-right">
                    {/* Editable Heading */}
                    <h3 className="tagging-heading">
                        {isEditingTitle ? (
                            <div className="editing-title">
                                <span>Tagging: </span>
                                <div className="file-name-container">
                                    <input
                                        type="text"
                                        value={fileBaseName}
                                        onChange={(e) => setFileBaseName(e.target.value)}
                                        className="tagging-heading-input"
                                        autoFocus
                                    />
                                    <span className="file-extension">{fileExtension}</span>
                                </div>
                                <button
                                    onClick={() => {
                                        if (fileBaseName.trim() === '') {
                                            alert('File name cannot be empty.');
                                            return;
                                        }
                                        // Optional: Add more validation for invalid characters
                                        setIsEditingTitle(false);
                                        // Update the File object with the new name
                                        const updatedFiles = [...selectedFiles];
                                        const oldFileName = currentFile.name;
                                        const newFileName = `${fileBaseName}${fileExtension}`;
                                        const newFile = new File(
                                            [currentFile],
                                            newFileName,
                                            { type: currentFile.type, lastModified: currentFile.lastModified }
                                        );
                                        updatedFiles[currentTagFileIndex] = newFile;
                                        setSelectedFiles(updatedFiles);
                                        // Transfer tags from old name to new name
                                        setTags(prevTags => {
                                            const newTags = { ...prevTags };
                                            newTags[newFileName] = newTags[oldFileName] || [];
                                            delete newTags[oldFileName];
                                            return newTags;
                                        });
                                    }}
                                    className="save-title-button"
                                >
                                    Save
                                </button>
                                <button
                                    onClick={() => {
                                        const { baseName } = splitFileName(currentFile.name);
                                        setFileBaseName(baseName);
                                        setFileExtension(splitFileName(currentFile.name).extension);
                                        setIsEditingTitle(false);
                                    }}
                                    className="cancel-title-button"
                                >
                                    Cancel
                                </button>
                            </div>
                        ) : (
                            <div className="heading-with-edit">
                                <span>Tagging: </span>
                                <div className="file-name-container">
                                    <span className="file-base-name">{fileBaseName}</span>
                                    <span className="file-extension">{fileExtension}</span>
                                </div>
                                <button
                                    onClick={() => setIsEditingTitle(true)}
                                    className="edit-heading-button"
                                    aria-label="Edit Filename"
                                >
                                    E
                                </button>
                            </div>
                        )}
                    </h3>

                    <div className="tags-container">
                        {currentTags.map((tag, index) => (
                            <div key={index} className="tagging-tag">
                                <input
                                    type="text"
                                    placeholder="Key"
                                    value={tag.key}
                                    onChange={(e) => handleTagChange(currentFile.name, index, 'key', e.target.value)}
                                    className="tag-input"
                                />
                                <input
                                    type="text"
                                    placeholder="Value"
                                    value={tag.value}
                                    onChange={(e) => handleTagChange(currentFile.name, index, 'value', e.target.value)}
                                    className="tag-input"
                                />
                                <button onClick={() => handleRemoveTag(currentFile.name, index)} className="remove-tag-button">×</button>
                            </div>
                        ))}
                    </div>
                    {currentTags.length < 10 && (
                        <button onClick={() => handleAddTag(currentFile.name)} className="add-tag-button">
                            Add Tag
                        </button>
                    )}
                    <div className="tagging-actions">
                        {currentTagFileIndex < selectedFiles.length - 1 ? (
                            <button onClick={handleCancel} className="cancel-button">
                                Cancel
                            </button>
                        ) : (
                            <div className="finish-buttons">
                                <button onClick={handleCancel} className="cancel-button">
                                    Cancel
                                </button>
                                <button onClick={handleFinish} className="finish-button">
                                    Finish
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            className={`upload-media-section-content ${isDragging ? 'dragging' : ''}`}
            onDrop={handleFileDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
        >
            <div className="upload-media-area">
                <div className="upload-media-icon">
                    <img src={uploadIcon} alt="Upload" />
                </div>
                <input
                    type="file"
                    id="file-upload"
                    multiple
                    onChange={handleFileSelect}
                    style={{ display: 'none' }}
                />
                <label htmlFor="file-upload" className="upload-media-button">
                    Choose Files
                </label>
                {selectedFiles.length > 0 && (
                    <div className="upload-media-selected-files">
                        {displayedFiles.map((file) => (
                            <div key={`${file.name}-${file.lastModified}`} className="upload-media-selected-file">
                                <p>{file.name}</p>
                                <button
                                    className="upload-media-remove-file"
                                    onClick={() => handleRemoveSelectedFile(file.name)}
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                        {remainingFilesCount > 0 && (
                            <div className="upload-media-remaining-files">
                                +{remainingFilesCount} more
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="upload-media-controls">
                <button onClick={handleFileUpload} className="upload-media-submit">
                    Upload
                </button>
            </div>
        </div>
    );
};

export default UploadMedia;
