// AccountManagement.js
import React, { useState } from 'react';
import UserManagement from './AccountManagement/UserManagement';
import SecurityCompliance from './AccountManagement/SecurityCompliance';
import './AccountManagement.css';

const AccountManagement = () => {
  const [activeSection, setActiveSection] = useState('companyProfile');

  const renderSection = () => {
    switch (activeSection) {
      case 'companyProfile':
        return <div className="section-content">Company Profile Section - Coming Soon</div>;
      case 'userManagement':
        return <UserManagement />;
      /*case 'apiKeysIntegrations':
        return <div className="section-content">API Keys & Integrations Section - Coming Soon</div>;*/
      case 'securityCompliance':
        return <SecurityCompliance />;
      case 'licensingSupport':
        return <div className="section-content">Licensing & Support Section - Coming Soon</div>;
      default:
        return <div className="section-content">Select a section</div>;
    }
  };

  return (
    <div className="account-management-container">
      <div className="sidebar">
        <ul>
          <li className={activeSection === 'companyProfile' ? 'active' : ''} onClick={() => setActiveSection('companyProfile')}>
            Company Profile
          </li>
          <li className={activeSection === 'userManagement' ? 'active' : ''} onClick={() => setActiveSection('userManagement')}>
            User Management
          </li>
          {/*}
          <li className={activeSection === 'apiKeysIntegrations' ? 'active' : ''} onClick={() => setActiveSection('apiKeysIntegrations')}>
            API Keys & Integrations
          </li> */}
          <li className={activeSection === 'securityCompliance' ? 'active' : ''} onClick={() => setActiveSection('securityCompliance')}>
            Security & Compliance
          </li>
          <li className={activeSection === 'licensingSupport' ? 'active' : ''} onClick={() => setActiveSection('licensingSupport')}>
            Licensing & Support
          </li>
        </ul>
      </div>
      <div className="main-content">{renderSection()}</div>
    </div>
  );
};

export default AccountManagement;
