// LoadingIndicator.js
import React from 'react';

const LoadingIndicator = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="loading-indicator" aria-live="assertive" aria-busy="true">
          <div className="spinner" role="status" aria-label="Loading"></div>
        </div>
      )}
    </>
  );
};

export default LoadingIndicator;
