import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebaseConfig';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      
      // Set the email cookie
      document.cookie = `userEmail=${email};path=/`;
      
      navigate('/'); // Redirect to the home page or any other page
    } catch (err) {
      setError('Failed to log in: ' + err.message);
    }
  };

  return (
    <div className="login-container">
      <div className="co-branding">
        <h1>Co-Branding</h1>
        <div className="co-branding-image">
          <img src="/images/Penn_Entertainment_logo.svg" alt="Co-Branding" />
        </div>
      </div>
      <div className="login-form">
        <img src="/images/elucicast_logo_text_1024x1024.svg" alt="Elucicast Logo" className="logo" />
        <h2>Sign in to your account</h2>
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit">Login</button>
          <a href="/forgot-password" className="forgot-password">Forgot Password?</a>
        </form>
      </div>
    </div>
  );
};

export default Login;
