// UserManagement.js
import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import ModifyUser from './ModifyUser';
import './UserManagement.css';

const UserManagement = () => {
  const [error, setError] = useState('');
  const [userRoles, setUserRoles] = useState([]);

  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (currentUser) {
      fetchUserRoles();
    }
  }, [currentUser]);

  const handleErrorResponse = (response) => {
    if (response.status === 401) {
      setError('Unauthorized access. Please log in again.');
    } else if (response.status === 403) {
      setError(
        'Forbidden access. You do not have permission to perform this action.'
      );
    } else {
      setError('An error occurred. Please try again later.');
    }
  };

  const fetchWithAuth = async (url, options = {}) => {
    const token = await currentUser.getIdToken();
    const defaultHeaders = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    const response = await fetch(url, {
      ...options,
      headers: {
        ...defaultHeaders,
        ...options.headers,
      },
    });
    if (!response.ok) {
      handleErrorResponse(response);
      throw new Error('Network response was not ok');
    }
    return response.json();
  };

  const fetchUserRoles = async () => {
    try {
      const data = await fetchWithAuth(
        `https://project-download-upload.wn.r.appspot.com/user-roles?email=${currentUser.email}`
      );
      setUserRoles(data.roles || []);
    } catch (error) {
      console.error('Error fetching user roles:', error);
    }
  };

  return (
    <div className="user-management-container">
      <ModifyUser />
    </div>
  );
};

export default UserManagement;
