// EncryptionSettings.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance';
import './EncryptionSettings.css';
// import { generateKeyPackage } from './KeyGenerator';

const EncryptionSettings = () => {
  const [customerName, setCustomerName] = useState('');
  const [customerUid, setCustomerUid] = useState('');
  const [keyType, setKeyType] = useState('managed'); // Default to managed key
  const [nextRotationDate, setNextRotationDate] = useState('');
  const [message, setMessage] = useState('');

  // Additional state for editable fields in provided key type
  const [keyName, setKeyName] = useState('');
  const [purpose, setPurpose] = useState('Symmetric encrypt/decrypt');
  const [algorithm, setAlgorithm] = useState('RSA_DECRYPT_OAEP_2048_SHA256');

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const lookupResponse = await axiosInstance.get('/api/customerLookup');
        const customerId = lookupResponse.data.customer_id;

        const nameResponse = await axiosInstance.get(`/api/customerName?customer_id=${customerId}`);
        setCustomerName(nameResponse.data.customer_name);

        const uidResponse = await axiosInstance.get(`/api/customerUID?customer_id=${customerId}`);
        setCustomerUid(uidResponse.data.customer_uid);

        // Calculate the Next Rotation Date
        setNextRotationDate(calculateNextRotationDate());
      } catch (error) {
        console.error('Error fetching customer details:', error);
        setMessage('An error occurred while fetching customer details.');
      }
    };

    fetchCustomerDetails();
  }, []);

  // Function to calculate the Next Rotation Date within 90 days
  const calculateNextRotationDate = () => {
    const currentDate = new Date();
    const rotationPeriodInDays = 90;

    const targetDate = new Date(currentDate.getTime() + rotationPeriodInDays * 24 * 60 * 60 * 1000);
    let nextRotationDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1);

    if (nextRotationDate > targetDate) {
      nextRotationDate = new Date(nextRotationDate.getFullYear(), nextRotationDate.getMonth() - 1, 1);
    }

    return nextRotationDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const handleKeyTypeChange = (e) => {
    const selectedKeyType = e.target.value;
    setKeyType(selectedKeyType);

    if (selectedKeyType === 'provided') {
      setMessage("Provided key feature is currently being developed. You can now customize key settings.");
    } else {
      setMessage('');
      setNextRotationDate(calculateNextRotationDate()); // Reset rotation date
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    if (keyType === 'provided') {
      setMessage("Provided key feature is currently being developed.");
      return;
    }

    try {
      const response = await axiosInstance.post('/api/encryption/create-key', {
        customerName,
        customerUid,
      });

      if (response.status === 200) {
        setMessage(`Success: ${response.data.message}`);
      } else {
        setMessage(`Error: ${response.data.error}`);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setMessage('An error occurred while creating the key.');
    }
  };

  /*
  const handleGenerateClick = async () => {
    try {
      const { keyData, fileName } = generateKeyPackage();
      
      // Create a blob for the key data and trigger the download
      const url = window.URL.createObjectURL(new Blob([keyData], { type: 'application/json' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      setMessage('Key generated and downloaded successfully.');
    } catch (error) {
      console.error('Error generating key:', error);
      setMessage('Failed to generate the key.');
    }
  };
  */

  const handleGenerateClick = async () => {
    try {
      const response = await axiosInstance.post('/api/encryption/generate-key', {
        customerName,
        customerUid,
      }, {
        responseType: 'blob', // Important to handle the file as a blob
      });
  
      // Create a URL for the blob and trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `elucicast-${customerName}-prod.json`);
      document.body.appendChild(link);
      link.click();
  
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error generating key:', error);
      setMessage('Failed to generate the key.');
    }
  };  

  const handleUploadClick = () => {
    console.log('Upload key clicked');
  };

  return (
    <div className="section-content">
      <h2>Encryption Key Management</h2>
      <form onSubmit={handleSubmit}>
        
        <fieldset>
          <legend>Customer Details</legend>
          <div className="form-grid">
            <div>
              <label>Customer Name:</label>
              <input type="text" value={customerName} disabled />
            </div>
            
            <div>
              <label>Customer UID:</label>
              <input type="text" value={customerUid} disabled />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Key Type</legend>
          <div className="form-grid">
            <div>
              <input
                type="radio"
                id="managed"
                name="keyType"
                value="managed"
                checked={keyType === 'managed'}
                onChange={handleKeyTypeChange}
              />
              <label htmlFor="managed">Managed Key</label>
            </div>
            <div>
              <input
                type="radio"
                id="provided"
                name="keyType"
                value="provided"
                checked={keyType === 'provided'}
                onChange={handleKeyTypeChange}
              />
              <label htmlFor="provided">Provided Key</label>
            </div>
          </div>
          {keyType === 'provided' && <p className="message">{message}</p>}
        </fieldset>
        
        <fieldset>
          <legend>Key Configuration</legend>
          <div className="key-configuration-grid">
            <div>
              <label>Key Name:</label>
              {keyType === 'managed' ? (
                <input type="text" value={`elucicast-${customerName}-prod`} disabled />
              ) : (
                <input
                  type="text"
                  value={keyName}
                  onChange={(e) => setKeyName(e.target.value)}
                />
              )}
            </div>
            
            <div>
              <label>Protection Level:</label>
              <input type="text" value="Software" disabled />
            </div>

            <div>
              <label>Purpose:</label>
              {keyType === 'managed' ? (
                <input type="text" value="Symmetric encrypt/decrypt" disabled />
              ) : (
                <select
                  value={purpose}
                  onChange={(e) => setPurpose(e.target.value)}
                >
                  <option value="Symmetric encrypt/decrypt">Symmetric encrypt/decrypt</option>
                  <option value="Asymmetric encrypt/decrypt">Asymmetric encrypt/decrypt</option>
                </select>
              )}
            </div>

            <div>
              <label>Algorithm:</label>
              {keyType === 'managed' ? (
                <input type="text" value="Google symmetric key" disabled />
              ) : (
                <select
                  value={algorithm}
                  onChange={(e) => setAlgorithm(e.target.value)}
                >
                  <option value="RSA_DECRYPT_OAEP_2048_SHA256">RSA_DECRYPT_OAEP_2048_SHA256</option>
                  <option value="RSA_DECRYPT_OAEP_3072_SHA256">RSA_DECRYPT_OAEP_3072_SHA256</option>
                  <option value="RSA_DECRYPT_OAEP_4096_SHA256">RSA_DECRYPT_OAEP_4096_SHA256</option>
                </select>
              )}
            </div>

            {keyType === 'managed' && (
              <>
                <div>
                  <label>Rotation Period:</label>
                  <input type="text" value="90 days" disabled />
                </div>
                <div>
                  <label>Next Rotation Date:</label>
                  <input type="text" value={nextRotationDate} disabled />
                </div>
              </>
            )}
          </div>
        </fieldset>

        <div className="button-group">
          {keyType === 'managed' ? (
            <button type="submit" className="submit-button right-aligned">Create Key</button>
          ) : (
            <>
              <button type="button" className="secondary-button" onClick={handleGenerateClick}>Generate</button>
              <button type="button" className="secondary-button" onClick={handleUploadClick}>Upload</button>
            </>
          )}
        </div>
      </form>
      
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default EncryptionSettings;
