import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDAMccTPGJNxj_7jFgIEjo2ch9OlffYltk",
  authDomain: "project-download-upload.firebaseapp.com",
  projectId: "project-download-upload",
  storageBucket: "project-download-upload.appspot.com",
  messagingSenderId: "23541478787",
  appId: "1:23541478787:web:3e9eb0033e055e4632b6"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
