// src/components/DeviceManagement/ConfigureDevice.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import './ConfigureDevice.css';
import CollapsibleSection from '../CollapsibleSection'; // Ensure correct import path
import ConfigureDeviceModal from './ConfigureDeviceModal';
import RegisterDevice from './RegisterDevice'; // Import RegisterDevice for modal
import yaml from 'js-yaml';
import debounce from 'lodash/debounce';
import API_BASE_URL from '../config'; // Centralized configuration

const ConfigureDevice = () => {
  // Authentication
  const auth = getAuth();
  const currentUser = auth.currentUser;

  // State variables for form data
  const [game, setGame] = useState('');
  const [minBet, setMinBet] = useState('');
  const [maxBet, setMaxBet] = useState('');
  const [submittedMinBet, setSubmittedMinBet] = useState('');
  const [submittedMaxBet, setSubmittedMaxBet] = useState('');
  const [isOnline, setIsOnline] = useState(false);
  const [games, setGames] = useState([]);

  // State variables for sidebar selections
  const [properties, setProperties] = useState([]);
  const [floors, setFloors] = useState([]);
  const [pits, setPits] = useState([]);
  const [tables, setTables] = useState([]); // Tables for the selected pit
  const [allTables, setAllTables] = useState([]); // All tables fetched from API
  const [filteredTables, setFilteredTables] = useState([]); // Tables after filtering
  const [selectedPropertyName, setSelectedPropertyName] = useState('');
  const [selectedFloorType, setSelectedFloorType] = useState('');
  const [selectedPitName, setSelectedPitName] = useState('');
  const [selectedTableId, setSelectedTableId] = useState('');
  const [selectedTableName, setSelectedTableName] = useState('');
  const [tableSID, setTableSID] = useState(''); // Reintroduced tableSID
  const [customerUID, setCustomerUID] = useState(''); // State for customer_uid

  // State for search functionality
  const [searchQuery, setSearchQuery] = useState('');

  // State to control which sections are collapsed
  const [isPropertyCollapsed, setIsPropertyCollapsed] = useState(false);
  const [isFloorCollapsed, setIsFloorCollapsed] = useState(false);
  const [isPitCollapsed, setIsPitCollapsed] = useState(false);
  const [isTableCollapsed, setIsTableCollapsed] = useState(false);

  // State to control Configure Device modal
  const [isConfigureModalOpen, setIsConfigureModalOpen] = useState(false);

  // State to control Register Device modal
  const [isRegisterDeviceModalOpen, setIsRegisterDeviceModalOpen] = useState(false);

  // State to control Context Menu
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0 });
  const [rightClickedTableId, setRightClickedTableId] = useState(null);

  // State to control sidebar visibility
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  // Debounce handler for search input using useRef
  const debounceSearchRef = useRef();

  if (!debounceSearchRef.current) {
    debounceSearchRef.current = debounce((query) => {
      setSearchQuery(query);
    }, 300);
  }

  // Handle Search Input Change
  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    debounceSearchRef.current(query);
  };

  // Fetch games
  const fetchGames = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/games`);
      console.log('Fetched games:', response.data);
      setGames(response.data);
    } catch (error) {
      console.error('Error fetching games:', error);
    }
  }, []);

  // Fetch properties
  const fetchProperties = useCallback(async () => {
    try {
      if (!currentUser) {
        console.error('User is not authenticated');
        return;
      }
      const token = await currentUser.getIdToken(true);

      const response = await axios.get(`${API_BASE_URL}/properties`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProperties(response.data);
      console.log('Fetched Properties:', response.data);
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  }, [currentUser]);

  // Get Customer UID
  const getCustomerUID = useCallback(async (customer_id) => {
    try {
      if (!customer_id) {
        // If customer_id is undefined, return null or handle accordingly
        return null;
      }
      const response = await axios.get(
        `${API_BASE_URL}/customerUID`,
        {
          params: { customer_id },
        }
      );
      return response.data.customer_uid;
    } catch (error) {
      console.error('Error fetching customer UID:', error);
      return null;
    }
  }, []);

  // Handle File Operations
  const checkAndHandleFile = useCallback(
    async (bucket, property_name, floor_type, floor_name, table_sid) => {
      if (!bucket || !property_name || !floor_type || !floor_name || !table_sid) {
        console.error('Missing parameters for file handling.');
        return;
      }

      const filePath = `${property_name}/${floor_type}/${floor_name}/${table_sid}/limits.yaml`;

      try {
        // Check if the file exists
        const checkResponse = await axios.get(`${API_BASE_URL}/checkFile`, {
          params: { bucket, filePath },
        });

        if (checkResponse.data.exists) {
          // File exists; read its content
          const readResponse = await axios.get(`${API_BASE_URL}/readFile`, {
            params: { bucket, filePath },
          });
          const yamlContent = readResponse.data.content;
          const limitsData = yaml.load(yamlContent);

          // Populate form with limitsData
          setGame(limitsData.game_name || ''); // Ensure game_name is set
          setMinBet(limitsData.min_bet || '');
          setMaxBet(limitsData.max_bet || '');
          setIsOnline(limitsData.is_online || false);
          setSubmittedMinBet(limitsData.min_bet || '');
          setSubmittedMaxBet(limitsData.max_bet || '');
        } else {
          // File does not exist; create it with default content
          const defaultContent = yaml.dump({
            game_name: '', // Default game_name can be empty or a default value
            min_bet: 10,
            max_bet: 1000,
            is_online: false,
            // Add other default configurations as needed
          });

          await axios.post(`${API_BASE_URL}/createFile`, {
            bucket,
            filePath,
            content: defaultContent,
          });

          // Populate form with default values
          setGame(''); // Set default or empty game_name
          setMinBet(10);
          setMaxBet(1000);
          setIsOnline(false);
          setSubmittedMinBet(10);
          setSubmittedMaxBet(1000);
        }
      } catch (error) {
        console.error('Error handling limits.yaml:', error);
        alert('Failed to load or create limits configuration.');
      }
    },
    []
  );

  // Fetch all tables across all properties
  const fetchAllTables = useCallback(async () => {
    try {
      if (!currentUser) {
        console.error('User is not authenticated');
        return;
      }
      const token = await currentUser.getIdToken(true);

      // Fetch all properties
      const propertiesResponse = await axios.get(`${API_BASE_URL}/properties`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const allProperties = propertiesResponse.data;
      console.log('Fetched Properties:', allProperties);

      // Use a Map to store unique tables
      const tableMap = new Map();

      for (const property of allProperties) {
        const propertyName = property.property_name;
        const propertyId = property.property_id;
        const customerId = property.customer_id; // Get customer_id from the property

        // Fetch floors for the property
        const floorsResponse = await axios.get(`${API_BASE_URL}/floors`, {
          params: { property_name: propertyName },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const floors = floorsResponse.data;

        for (const floor of floors) {
          const floorType = floor.floor_type;

          // Fetch pits for the floor and property
          const pitsResponse = await axios.get(`${API_BASE_URL}/pits`, {
            params: { floor_type: floorType, property_name: propertyName },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const pits = pitsResponse.data;

          for (const pit of pits) {
            const floorName = pit.floor_name;

            // Fetch tables for the pit, floor, and property
            const tablesResponse = await axios.get(`${API_BASE_URL}/tables`, {
              params: { floor_type: floorType, floor_name: floorName, property_name: propertyName },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const fetchedTables = tablesResponse.data;

            for (const table of fetchedTables) {
              // Assign necessary fields to each table
              table.property_id = propertyId;
              table.property_name = propertyName;
              table.customer_id = customerId; // Add customer_id to each table
              table.floor_type = floorType; // Ensure floor_type is defined
              table.floor_name = floorName;
              // Assuming customer_uid is part of the table data or can be fetched
              // If not, you may need to adjust how you obtain customer_uid
              if (!tableMap.has(table.table_id)) {
                tableMap.set(table.table_id, table);
              } else {
                console.log(`Duplicate table found: ${table.table_id}`);
              }
            }
          }
        }
      }

      const combinedTables = Array.from(tableMap.values());
      setAllTables(combinedTables);
      setFilteredTables(combinedTables); // Initially, display all tables
      console.log('Fetched All Unique Tables:', combinedTables);
    } catch (error) {
      console.error('Error fetching all tables:', error.response ? error.response.data : error.message);
    }
  }, [currentUser]);

  // Fetch floors
  const fetchFloors = useCallback(
    async (property_name) => {
      try {
        const token = await currentUser.getIdToken(true);
        const response = await axios.get(`${API_BASE_URL}/floors`, {
          params: { property_name },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFloors(response.data);
        console.log('Fetched Floors:', response.data);
      } catch (error) {
        console.error('Error fetching floors:', error);
      }
    },
    [currentUser]
  );

  // Fetch pits
  const fetchPits = useCallback(
    async (floor_type, property_name) => {
      try {
        const token = await currentUser.getIdToken(true);
        const response = await axios.get(`${API_BASE_URL}/pits`, {
          params: { floor_type, property_name },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPits(response.data);
        console.log('Fetched Pits:', response.data);
      } catch (error) {
        console.error('Error fetching pits:', error);
      }
    },
    [currentUser]
  );

  // Fetch tables for a specific pit
  const fetchTables = useCallback(
    async (floor_type, floor_name, property_name) => {
      try {
        const token = await currentUser.getIdToken(true);
        const response = await axios.get(`${API_BASE_URL}/tables`, {
          params: { floor_type, floor_name, property_name },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTables(response.data);
        console.log('Fetched Tables:', response.data);
      } catch (error) {
        console.error('Error fetching tables:', error.response ? error.response.data : error.message);
      }
    },
    [currentUser]
  );

  // Effect to fetch data on component mount
  useEffect(() => {
    fetchGames();
    fetchProperties();
    fetchAllTables();
  }, [fetchGames, fetchProperties, fetchAllTables]);

 //Handle Filter Events
  const [activeFilters, setActiveFilters] = useState({
    propertyName: '',
    floorType: '',
    pitName: ''
  });
  

  // Handle Property Selection
  const handlePropertySelection = (propertyName) => {
    setActiveFilters((prevFilters) => {
      if (prevFilters.propertyName === propertyName) {
        // Clear property filter and all downstream filters
        setSelectedPropertyName('');
        setSelectedFloorType('');
        setSelectedPitName('');
        setFloors([]);
        setPits([]);
        setTables([]);
        setIsPropertyCollapsed(false); // Keep Property section open if cleared
        return { propertyName: '', floorType: '', pitName: '' };
      } else {
        // Set new property filter, clear downstream selections, and collapse section
        setSelectedPropertyName(propertyName);
        setSelectedFloorType('');
        setSelectedPitName('');
        setFloors([]);
        setPits([]);
        setTables([]);
        setIsPropertyCollapsed(true); // Collapse Property section
        setIsFloorCollapsed(false);   // Expand Floor section for next selection
        fetchFloors(propertyName);
        return { propertyName, floorType: '', pitName: '' };
      }
    });
  };
  
  // Handle Floor Selection
  const handleFloorSelection = (floorType) => {
    setActiveFilters((prevFilters) => {
      if (prevFilters.floorType === floorType) {
        // Clear floor filter and all downstream filters
        setSelectedFloorType('');
        setSelectedPitName('');
        setPits([]);
        setTables([]);
        setIsFloorCollapsed(false); // Keep Floor section open if cleared
        return { ...prevFilters, floorType: '', pitName: '' };
      } else {
        // Set new floor filter, clear downstream selections, and collapse section
        setSelectedFloorType(floorType);
        setSelectedPitName('');
        setPits([]);
        setTables([]);
        setIsFloorCollapsed(true);  // Collapse Floor section
        setIsPitCollapsed(false);   // Expand Pit section for next selection
        fetchPits(floorType, activeFilters.propertyName);
        return { ...prevFilters, floorType, pitName: '' };
      }
    });
  };
  
  // Handle Pit Selection
  const handlePitSelection = (pitName) => {
    setActiveFilters((prevFilters) => {
      if (prevFilters.pitName === pitName) {
        // Clear pit filter
        setSelectedPitName('');
        setTables([]);
        setIsPitCollapsed(false); // Keep Pit section open if cleared
        return { ...prevFilters, pitName: '' };
      } else {
        // Set new pit filter and collapse section
        setSelectedPitName(pitName);
        setTables([]);
        setIsPitCollapsed(true); // Collapse Pit section
        fetchTables(activeFilters.floorType, pitName, activeFilters.propertyName);
        return { ...prevFilters, pitName };
      }
    });
  };
  

  // Handle Table Selection
  const handleTableSelection = async (tableId, tableName) => {
    console.log('Selected Table:', tableName);
    setSelectedTableId(tableId);
    setSelectedTableName(tableName);

    // **Collapse all individual sections**
    setIsPropertyCollapsed(true);
    setIsFloorCollapsed(true);
    setIsPitCollapsed(true);
    setIsTableCollapsed(true);

    // Fetch table data from the selected table
    const selectedTableData = allTables.find((table) => table.table_id === tableId);
    if (selectedTableData) {
      const {
        table_sid,
        customer_id,
        customer_uid, // Assuming customer_uid is part of the table data
        property_name,
        floor_type: tableFloorType, // Renamed to avoid scope issues
        floor_name,
      } = selectedTableData;

      setTableSID(table_sid); // Reintroduced and set tableSID
      setCustomerUID(customer_uid); // Set customer_uid

      // Only fetch customer_uid if customer_id is defined
      if (customer_id) {
        const customer_uid_fetched = await getCustomerUID(customer_id);
        setCustomerUID(customer_uid_fetched);
      }

      // Backfill sidebar selections
      setSelectedPropertyName(property_name);
      setSelectedFloorType(tableFloorType); // Use tableFloorType
      setSelectedPitName(floor_name);

      // Fetch corresponding floors, pits, and tables to update sidebar
      fetchFloors(property_name);
      fetchPits(tableFloorType, property_name); // Use tableFloorType
      fetchTables(tableFloorType, floor_name, property_name);
    }
  };

  // Add sorting state
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  // Pagination state variables
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // You can adjust this as needed

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredTables.length / itemsPerPage);

  // Get current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTables = filteredTables.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Reset to first page when filteredTables changes
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredTables]);

  // Effect to filter and sort tables based on selections, search query, and sort configuration
  useEffect(() => {
    let filtered = allTables;
  
    if (activeFilters.propertyName) {
      filtered = filtered.filter((table) => table.property_name === activeFilters.propertyName);
    }
  
    if (activeFilters.floorType) {
      filtered = filtered.filter((table) => table.floor_type === activeFilters.floorType);
    }
  
    if (activeFilters.pitName) {
      filtered = filtered.filter((table) => table.floor_name === activeFilters.pitName);
    }
  
    if (searchQuery.trim()) {
      const lowercasedQuery = searchQuery.toLowerCase();
      filtered = filtered.filter((table) =>
        Object.values(table).some((value) => String(value).toLowerCase().includes(lowercasedQuery))
      );
    }
  
    setFilteredTables(filtered);
  }, [allTables, activeFilters, searchQuery]);  

  // Sorting handler
  const handleSort = (columnKey) => {
    setSortConfig((prevConfig) => {
      // Toggle direction if the same column is clicked
      const newDirection = prevConfig.key === columnKey && prevConfig.direction === 'ascending'
        ? 'descending'
        : 'ascending';
        
      return { key: columnKey, direction: newDirection };
    });
    // Add sorting logic here if needed based on updated sortConfig
  };  

  // Handle New Device - Open Register Device Modal
  const handleNewDevice = () => {
    console.log('New Device button clicked');
    setIsRegisterDeviceModalOpen(true); // Open the RegisterDevice modal
  };

  // Handle Configure Device - Open Configure Device Modal
  const handleConfigureDevice = async () => {
    console.log('Configure Device button clicked');
    if (selectedTableId) {
      // Ensure all required data is available
      const selectedTableData = allTables.find((table) => table.table_id === selectedTableId);
      if (selectedTableData) {
        const { table_sid, customer_uid } = selectedTableData; // Ensure table_sid is destructured
        const bucket = customerUID; // Use customerUID from state instead of customer_uid from table data

        // Use state variables for property_name, floor_type, floor_name
        const property_name = selectedPropertyName;
        const floor_type = selectedFloorType;
        const floor_name = selectedPitName;

        console.log('Bucket:', bucket);
        console.log('Property Name:', property_name);
        console.log('Floor Type:', floor_type);
        console.log('Floor Name:', floor_name);
        console.log('Table SID:', table_sid);
        console.log('Customer UID:', customer_uid);

        if (bucket && property_name && floor_type && floor_name && table_sid && customer_uid) {
          // Fetch or create limits.yaml and populate the form
          await checkAndHandleFile(bucket, property_name, floor_type, floor_name, table_sid);
          setIsConfigureModalOpen(true);
        } else {
          alert('Missing necessary data to configure the device.');
        }
      } else {
        alert('Selected table data not found.');
      }
    } else {
      alert('Please select a device to configure.');
    }
  };

  // Handle Remove Device
  const handleRemoveDevice = () => {
    console.log('Remove Device button clicked');
    if (selectedTableId) {
      if (window.confirm(`Are you sure you want to remove device "${selectedTableName}"?`)) {
        // Implement the API call to remove the device
        console.log(`Device "${selectedTableName}" removed.`);
        // Reset selections
        setSelectedTableId('');
        setSelectedTableName('');
        setTableSID('');
        setCustomerUID('');
        // Optionally, remove from UI or fetch updated list
      }
    } else {
      alert('Please select a device to remove.');
    }
  };

  // Open context menu
  const handleContextMenu = (e, tableId, tableName) => {
    e.preventDefault(); // Prevent the default browser context menu
  
    // Call handleTableSelection to select the table (just like a left-click)
    handleTableSelection(tableId, tableName);
  
    // Set the right-clicked table ID and open the custom context menu
    setRightClickedTableId(tableId);
    setContextMenu({ visible: true, x: e.pageX, y: e.pageY });
  };
   

  // Close context menu when clicking elsewhere
  const handleClickOutside = () => setContextMenu({ visible: false, x: 0, y: 0 });

  // Handle Configure from context menu
  const handleConfigureContext = () => {
    setSelectedTableId(rightClickedTableId);
    handleConfigureDevice();
    setContextMenu({ visible: false, x: 0, y: 0 });
  };

  // Handle Remove from context menu
  const handleRemoveContext = () => {
    setSelectedTableId(rightClickedTableId);
    handleRemoveDevice();
    setContextMenu({ visible: false, x: 0, y: 0 });
  };

  // Close context menu when clicking outside
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Handle Submit Configuration
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate inputs
    const minBetInt = parseInt(minBet, 10);
    const maxBetInt = parseInt(maxBet, 10);

    if (isNaN(minBetInt) || isNaN(maxBetInt)) {
      alert('Please enter valid integer values for Min Bet and Max Bet');
      return;
    }

    // Set the submitted values (no formatting)
    setSubmittedMinBet(minBetInt);
    setSubmittedMaxBet(maxBetInt);

    const gameData = {
      game_name: game,
      min_bet: minBetInt,
      max_bet: maxBetInt,
      is_online: isOnline,
    };

    try {
      // Construct the file path using table_sid
      const filePath = `${selectedPropertyName}/${selectedFloorType}/${selectedPitName}/${tableSID}/limits.yaml`;

      // Serialize gameData to YAML
      const yamlContent = yaml.dump(gameData);

      // Update the limits.yaml file
      await axios.post(`${API_BASE_URL}/updateFile`, {
        bucket: customerUID, // Assuming bucket is customer_uid
        filePath,
        content: yamlContent,
      });

      // Reset the input fields after successful submission
      setMinBet('');
      setMaxBet('');

      alert('Device configured successfully!');
      // Close the Configure Device Modal after submission
      setIsConfigureModalOpen(false);
    } catch (error) {
      console.error('Error configuring device:', error);
      alert('Failed to configure device.');
    }
  };

  return (
    <div className="configure-device-container" onContextMenu={(e) => e.preventDefault()}>
      {/* Sidebar */}
      <div className={`collapsible-section-container ${isSidebarCollapsed ? 'collapsed' : ''}`}>
        {/* Sidebar Toggle Button */}
        <button
          className="toggle-container-button"
          onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
          aria-label={isSidebarCollapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}
        >
          {isSidebarCollapsed ? '>' : '<'}
        </button>

        {/* Sidebar Content */}
        {!isSidebarCollapsed && (
          <div className="sidebar-content">
            {/* Property Section */}
            <CollapsibleSection
              title="Property"
              isOpen={!isPropertyCollapsed}
              toggleOpen={() => setIsPropertyCollapsed(!isPropertyCollapsed)}
              selectedOption={selectedPropertyName}
            >
              <ul className="option-list">
                {properties.map((property, index) => (
                  <li
                    key={index}
                    className={`option-item ${selectedPropertyName === property.property_name ? 'selected' : ''}`}
                    onClick={() => handlePropertySelection(property.property_name)}
                  >
                    {property.property_name}
                  </li>
                ))}
              </ul>
            </CollapsibleSection>

            {/* Floor Section */}
            <CollapsibleSection
              title="Floor"
              isOpen={!isFloorCollapsed && selectedPropertyName !== ''}
              toggleOpen={() => setIsFloorCollapsed(!isFloorCollapsed)}
              selectedOption={selectedFloorType}
            >
              {selectedPropertyName ? (
                <ul className="option-list">
                  {floors.map((floor, index) => (
                    <li
                      key={index}
                      className={`option-item ${selectedFloorType === floor.floor_type ? 'selected' : ''}`}
                      onClick={() => handleFloorSelection(floor.floor_type)}
                    >
                      {floor.floor_type}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Please select a property first.</p>
              )}
            </CollapsibleSection>

            {/* Pit Section */}
            <CollapsibleSection
              title="Pit"
              isOpen={!isPitCollapsed && selectedFloorType !== ''}
              toggleOpen={() => setIsPitCollapsed(!isPitCollapsed)}
              selectedOption={selectedPitName}
            >
              {selectedFloorType ? (
                <ul className="option-list">
                  {pits.map((pit, index) => (
                    <li
                      key={index}
                      className={`option-item ${selectedPitName === pit.floor_name ? 'selected' : ''}`}
                      onClick={() => handlePitSelection(pit.floor_name)}
                    >
                      {pit.floor_name}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Please select a floor first.</p>
              )}
            </CollapsibleSection>

            {/* Table Section */}
            <CollapsibleSection
              title="Table"
              isOpen={!isTableCollapsed && selectedPitName !== ''}
              toggleOpen={() => setIsTableCollapsed(!isTableCollapsed)}
              selectedOption={selectedTableName}
            >
              {selectedPitName ? (
                <ul className="option-list">
                  {tables.map((table, index) => (
                    <li
                      key={index}
                      className={`option-item ${selectedTableId === table.table_id ? 'selected' : ''}`}
                      onClick={() => handleTableSelection(table.table_id, table.table_name)}
                      onContextMenu={(e) => handleContextMenu(e, table.table_id)}
                    >
                      {table.table_name}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Please select a pit first.</p>
              )}
            </CollapsibleSection>
          </div>
        )}
      </div>

      {/* Main Content */}
      <div className={`configure-device-main ${isSidebarCollapsed ? 'expanded' : ''}`}>
        {/* Search and Action Buttons */}
        <div className="search-action-container">
          {/* Search Bar */}
          <input
            type="text"
            className="search-bar"
            placeholder="Search devices..."
            onChange={handleSearchInputChange}
          />

          {/* Action Buttons */}
          <div className="action-buttons">
            <button type="button" className="action-button new-device" onClick={handleNewDevice}>
              New Device
            </button>
            <button type="button" className="action-button configure-device" onClick={handleConfigureDevice}>
              Configure Device
            </button>
            <button type="button" className="action-button remove-device" onClick={handleRemoveDevice}>
              Remove Device
            </button>
          </div>
        </div>

        {/* Table Display */}
        <div className="table-container">
          {filteredTables.length === 0 ? (
            <p>No devices found.</p>
          ) : (
            <>
              <table className="devices-table">
                <thead>
                  <tr>
                    {['property_name', 'floor_type', 'floor_name', 'table_name', 'current_game', 'min_bet', 'max_bet', 'assigned_screen'].map((column) => (
                      <th
                        key={column}
                        onClick={() => handleSort(column)}
                        className={`sortable-header ${sortConfig.key === column ? 'sorted' : ''}`}
                      >
                        <span>
                          {column.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())} {/* Formats column name */}
                          {sortConfig.key === column && (
                            <span className="sort-arrow">{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentTables.map((table, index) => (
                    <tr
                    key={index}
                    className={selectedTableId === table.table_id ? 'selected-row' : ''}
                    onClick={() => handleTableSelection(table.table_id, table.table_name)}
                    onContextMenu={(e) => handleContextMenu(e, table.table_id, table.table_name)}
                    >
                      <td>{table.property_name}</td>
                      <td>{table.floor_type}</td>
                      <td>{table.floor_name}</td>
                      <td>{table.table_name}</td>
                      <td>{table.current_game || 'N/A'}</td>
                      <td>{table.min_bet || 'N/A'}</td>
                      <td>{table.max_bet || 'N/A'}</td>
                      <td>{table.assigned_screen || 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Pagination Controls */}
              <div className="pagination-controls">
                <button
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                  className="pagination-button"
                  aria-label="Go to first page"
                >
                  «
                </button>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="pagination-button"
                  aria-label="Go to previous page"
                >
                  ‹
                </button>
                <span className="pagination-info">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="pagination-button"
                  aria-label="Go to next page"
                >
                  ›
                </button>
                <button
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                  className="pagination-button"
                  aria-label="Go to last page"
                >
                  »
                </button>
              </div>
            </>
          )}
        </div>

        {/* Configure Device Modal */}
        {isConfigureModalOpen && (
          <ConfigureDeviceModal
            isOpen={isConfigureModalOpen}
            onRequestClose={() => setIsConfigureModalOpen(false)}
            isOnline={isOnline}
            setIsOnline={setIsOnline}
            game={game}
            games={games}
            setGame={setGame}
            minBet={minBet}
            setMinBet={setMinBet}
            maxBet={maxBet}
            setMaxBet={setMaxBet}
            submittedMinBet={submittedMinBet}
            submittedMaxBet={submittedMaxBet}
            handleSubmit={handleSubmit}
          />
        )}

        {/* Register Device Modal */}
        <RegisterDevice
          isOpen={isRegisterDeviceModalOpen}
          onRequestClose={() => setIsRegisterDeviceModalOpen(false)}
        />
      </div>
      {contextMenu.visible && (
        <div
          className="context-menu"
          style={{ top: contextMenu.y, left: contextMenu.x, position: 'absolute' }}
        >
          <button onClick={handleConfigureContext}>Configure</button>
          <button onClick={handleRemoveContext}>Remove</button>
        </div>
      )}
    </div>
  );
};

export default ConfigureDevice;
