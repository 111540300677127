// src/DeviceManagement/NewRequest.js

import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import './DeviceSupportTicket.css';

const NewRequest = ({ onClose, onSubmit }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    property: '',
    firstName: '',
    lastName: '',
    email: '',
    failureType: 'Broken Device', // Default value
    otherFailureType: '',
    serialNumber: '',
    description: '',
  });
  const [properties, setProperties] = useState([]);
  const [message, setMessage] = useState('');
  const [serialNumberError, setSerialNumberError] = useState(''); // New state for error message

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          throw new Error('User not authenticated');
        }

        const idToken = await user.getIdToken();

        const response = await fetch('https://project-download-upload.wn.r.appspot.com/api/properties', {
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch properties.');
        }

        const data = await response.json();
        setProperties(data);
      } catch (error) {
        console.error('Error fetching properties:', error);
        setMessage(`Error fetching properties: ${error.message}`);
      }
    };

    fetchProperties();
  }, []);

  // Function to handle serial number input changes
  const handleSerialNumberChange = (e) => {
    let value = e.target.value.toUpperCase(); // Convert to uppercase
    value = value.replace(/[^0-9A-F]/g, '');  // Remove non-hex characters
    if (value.length > 6) {
      value = value.substring(0, 6);          // Limit to 6 characters
    }

    setFormData({ ...formData, serialNumber: value });

    // Validate serial number length
    if (value.length !== 6) {
      setSerialNumberError('Serial number must be exactly 6 hexadecimal characters.');
    } else {
      setSerialNumberError('');
    }
  };

  // Function to determine requestTypeId based on failure type
  const getRequestTypeId = (failureType) => {
    return failureType === 'Broken Device' ? '9' : '8';
  };

  // Form steps for navigation
  const steps = [
    {
      label: 'Property',
      component: (
        <div className="form-group">
          <label htmlFor="property">Select Property:</label>
          <select
            id="property"
            value={formData.property}
            onChange={(e) => setFormData({ ...formData, property: e.target.value })}
            required
          >
            <option value="">Select Property</option>
            {properties.map((property) => (
              <option key={property.property_id} value={property.property_name}>
                {property.property_name}
              </option>
            ))}
          </select>
        </div>
      ),
    },
    {
      label: 'Failure Information',
      component: (
        <>
          <div className="form-group">
            <label htmlFor="failureType">Failure Type:</label>
            <select
              id="failureType"
              value={formData.failureType}
              onChange={(e) => setFormData({ ...formData, failureType: e.target.value, otherFailureType: '' })}
              required
            >
              <option value="Broken Device">Broken Device</option>
              <option value="Other">Other</option>
            </select>
          </div>
          {formData.failureType === 'Other' && (
            <div className="form-group">
              <label htmlFor="otherFailureType">Please specify:</label>
              <input
                type="text"
                id="otherFailureType"
                value={formData.otherFailureType}
                onChange={(e) => setFormData({ ...formData, otherFailureType: e.target.value })}
                required
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="serialNumber">Serial Number:</label>
            <input
              type="text"
              id="serialNumber"
              value={formData.serialNumber}
              onChange={handleSerialNumberChange}
              required
            />
            {serialNumberError && <p className="error-message">{serialNumberError}</p>}
          </div>
        </>
      ),
    },
    {
      label: 'Contact Information',
      component: (
        <>
          <div className="form-group">
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              value={formData.firstName}
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              value={formData.lastName}
              onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              required
            />
          </div>
        </>
      ),
    },
    {
      label: 'Description',
      component: (
        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            required
          />
        </div>
      ),
    },
  ];

  const nextStep = () => {
    // Validate serial number before proceeding to the next step
    if (currentStep === 1 && serialNumberError) {
      setMessage('Please correct the errors before proceeding.');
      return;
    }

    setCurrentStep((prevStep) => prevStep + 1);
    setMessage(''); // Clear any previous messages
  };

  const prevStep = () => setCurrentStep((prevStep) => prevStep - 1);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Final validation before submission
    if (serialNumberError) {
      setMessage('Please correct the errors before submitting.');
      return;
    }

    setMessage('Submitting...');

    // Determine the actual failure type
    const actualFailureType =
      formData.failureType === 'Other' ? formData.otherFailureType : formData.failureType;

    // Determine requestTypeId based on actual failure type
    const requestTypeId = getRequestTypeId(formData.failureType);

    // Prepare email data
    const emailData = {
      to: 'noreply@elucicast.com',
      subject: `${formData.property} - ${formData.serialNumber} - ${actualFailureType} - Device Support`,
      text: `
        Property: ${formData.property}
        Device Serial Number: ${formData.serialNumber}
        Failure Type: ${actualFailureType}
        Email: ${formData.email}
        First Name: ${formData.firstName}
        Last Name: ${formData.lastName}
        Description: ${formData.description}
      `,
    };

    // Prepare data for Jira incident creation
    const jiraData = {
      serviceDeskId: '1',
      requestTypeId,
      requestFieldValues: {
        summary: `${actualFailureType || ''} - ${formData.serialNumber || ''}`,
        description: `Property: ${formData.property || ''}
Failure Type: ${actualFailureType || ''}
Device Serial Number: ${formData.serialNumber || ''}
Email: ${formData.email || ''}
Description: ${formData.description || ''}`,
      },
      raiseOnBehalfOf: formData.email || '',
    };

    try {
      // Send email
      const emailResponse = await fetch('https://project-download-upload.wn.r.appspot.com/sendSupportEmail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailData),
      });

      if (!emailResponse.ok) {
        throw new Error('Failed to send email.');
      }

      // Create Jira ticket
      const jiraResponse = await fetch('https://project-download-upload.wn.r.appspot.com/api/create-incident', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(jiraData),
      });

      if (!jiraResponse.ok) {
        throw new Error('Failed to create Jira issue.');
      }

      setMessage('Ticket and email created successfully!');
      onSubmit(formData); // Notify parent component
      onClose(); // Close popup
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="popup-close-button" onClick={onClose}>
          &times;
        </button>
        <div className="form-container">
          <div className="form-left">
            {steps[currentStep].component}
            <div className="form-navigation">
              {currentStep > 0 && <button onClick={prevStep}>Back</button>}
              {currentStep < steps.length - 1 && <button onClick={nextStep}>Next</button>}
              {currentStep === steps.length - 1 && <button type="submit" onClick={handleSubmit}>Submit</button>}
            </div>
            {message && <p className="form-message">{message}</p>}
          </div>
          {/* Preview Section */}
          <div className="form-right">
            <div className="form-summary">
              <h3>Preview</h3>
              <p><strong>Property:</strong> {formData.property || 'N/A'}</p>
              <p><strong>First Name:</strong> {formData.firstName || 'N/A'}</p>
              <p><strong>Last Name:</strong> {formData.lastName || 'N/A'}</p>
              <p><strong>Email:</strong> {formData.email || 'N/A'}</p>
              <p>
                <strong>Failure Type:</strong>{' '}
                {formData.failureType === 'Other' ? formData.otherFailureType : formData.failureType || 'N/A'}
              </p>
              <p><strong>Device Serial Number:</strong> {formData.serialNumber || 'N/A'}</p>
              <p><strong>Description:</strong> {formData.description || 'N/A'}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewRequest;
