import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import imageIcon from '../content/imageIcon.svg';

const ManifestFilesList = ({
  displayedManifestFiles,
  onDragEnd,
  handleRemoveFile,
  handlePreviewClick,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="manifestFiles">
        {(provided) => (
          <ul
            className="option-list"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
          {displayedManifestFiles
            .filter((fileObj) => {
              console.log('Manifest File Object:', fileObj); // Debugging log
              return fileObj.file && fileObj.file.trim() !== '';
            })
            .map((fileObj, index) => (
              <Draggable key={fileObj.file} draggableId={fileObj.file} index={index}>
                {(provided) => (
                  <li
                    className={`option-item ${
                      fileObj.status === 'stagedAddition' ? 'added' : ''
                    }`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={() => handleRemoveFile(fileObj.file)}
                  >
                    <img
                      src={imageIcon}
                      alt="Preview Icon"
                      className="preview-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePreviewClick(fileObj.file);
                      }}
                    />
                    {fileObj.friendlyName !== 'Unknown'
                      ? fileObj.friendlyName
                      : 'Unknown'}
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ManifestFilesList;
