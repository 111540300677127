import React, { useState, useEffect } from 'react';
import './DeviceSupportTicket.css';
import NewRequest from './newRequest';
import { getAuth } from 'firebase/auth';

const DeviceSupportTicket = () => {
  const [showNewRequest, setShowNewRequest] = useState(false);
  const [openIncidents, setOpenIncidents] = useState([]);
  const [closedIncidents, setClosedIncidents] = useState([]);
  const [openVisible, setOpenVisible] = useState(true);
  const [closedVisible, setClosedVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [message, setMessage] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [openPage, setOpenPage] = useState(1);
  const [closedPage, setClosedPage] = useState(1);
  const [myTicketsOnly, setMyTicketsOnly] = useState(false); // New state for filtering tickets
  const [currentUserEmail, setCurrentUserEmail] = useState(''); // New state for user's email
  const RESULTS_PER_PAGE = 15;

  useEffect(() => {
    // Fetch the current user's email
    const fetchCurrentUser = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        setCurrentUserEmail(user.email);
      }
    };
    
    fetchCurrentUser();
    fetchIncidents();
  }, []);

  const fetchIncidents = async () => {
    try {
      const response = await fetch('https://project-download-upload.wn.r.appspot.com/api/open-incidents');
      if (response.ok) {
        const data = await response.json();
        setOpenIncidents(
          data.filter(ticket => ['Open', 'Work In Progress', 'Pending'].includes(ticket.fields.status?.name || ''))
        );
        setClosedIncidents(
          data.filter(ticket => ['Closed', 'Completed', 'Canceled'].includes(ticket.fields.status?.name || ''))
        );
      } else {
        setMessage('Failed to fetch incidents.');
      }
    } catch (error) {
      setMessage('An error occurred: ' + error.message);
    }
  };

  // Handle sorting logic for a given key
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Sort incidents based on the current sort configuration
  const sortIncidents = (incidents) => {
    if (!sortConfig.key) return incidents;

    return [...incidents].sort((a, b) => {
      let aValue, bValue;

      if (sortConfig.key === 'key') {
        aValue = a.key.toLowerCase();
        bValue = b.key.toLowerCase();
      } else {
        aValue = a.fields[sortConfig.key] || '';
        bValue = b.fields[sortConfig.key] || '';
      }

      if (sortConfig.key === 'created') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      } else if (sortConfig.key === 'reporter') {
        aValue = a.fields.reporter?.displayName.toLowerCase() || '';
        bValue = b.fields.reporter?.displayName.toLowerCase() || '';
      } else if (sortConfig.key === 'status') {
        aValue = a.fields.status?.name.toLowerCase() || '';
        bValue = b.fields.status?.name.toLowerCase() || '';
      } else if (sortConfig.key !== 'key') {
        aValue = aValue.toString().toLowerCase();
        bValue = bValue.toString().toLowerCase();
      }

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const paginate = (incidents, page) => {
    const startIndex = (page - 1) * RESULTS_PER_PAGE;
    return incidents.slice(startIndex, startIndex + RESULTS_PER_PAGE);
  };

  const totalPages = (incidents) => Math.ceil(incidents.length / RESULTS_PER_PAGE);

  const filteredOpenIncidents = paginate(
    sortIncidents(openIncidents.filter((incident) => {
      const searchValue = searchText.toLowerCase();
      const matchesSearch = (
        incident.key.toLowerCase().includes(searchValue) ||
        incident.fields.summary.toLowerCase().includes(searchValue) ||
        (incident.fields.reporter?.displayName.toLowerCase() || '').includes(searchValue) ||
        (incident.fields.status?.name.toLowerCase() || '').includes(searchValue) ||
        new Date(incident.fields.created).toLocaleDateString().includes(searchValue)
      );
      const matchesUser = !myTicketsOnly || incident.fields.reporter?.emailAddress === currentUserEmail;
      return matchesSearch && matchesUser;
    })),
    openPage
  );
  
  const filteredClosedIncidents = paginate(
    sortIncidents(closedIncidents.filter((incident) => {
      const searchValue = searchText.toLowerCase();
      const matchesSearch = (
        incident.key.toLowerCase().includes(searchValue) ||
        incident.fields.summary.toLowerCase().includes(searchValue) ||
        (incident.fields.reporter?.displayName.toLowerCase() || '').includes(searchValue) ||
        (incident.fields.status?.name.toLowerCase() || '').includes(searchValue) ||
        new Date(incident.fields.created).toLocaleDateString().includes(searchValue)
      );
      const matchesUser = !myTicketsOnly || incident.fields.reporter?.emailAddress === currentUserEmail;
      return matchesSearch && matchesUser;
    })),
    closedPage
  );

  // Function to render sort arrows on the right side
  const renderSortArrows = (key) => {
    return (
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <span
          style={{
            color: sortConfig.key === key && sortConfig.direction === 'asc' ? 'blue' : 'black',
            cursor: 'pointer',
            marginRight: '2px'
          }}
          onClick={() => handleSort(key)}
        >
          ▲
        </span>
        <span
          style={{
            color: sortConfig.key === key && sortConfig.direction === 'desc' ? 'blue' : 'black',
            cursor: 'pointer'
          }}
          onClick={() => handleSort(key)}
        >
          ▼
        </span>
      </div>
    );
  };

  return (
    <div className="device-support-ticket">
      {/* Header Section with Search and Buttons */}
      <div className="device-support-ticket-header">
        <div className="device-support-ticket-search-box">
          <input
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search tickets..."
          />
          <button onClick={() => { setSearchText(''); setMyTicketsOnly(false); }}>All Tickets</button>
        </div>
        <button onClick={() => setMyTicketsOnly(true)}>My Tickets</button>
        <button onClick={() => setShowNewRequest(true)}>New Ticket</button>
      </div>

      {/* Open and Closed Tickets Sections */}
      <div className="existing-support-ticket">
        <h3>Existing Support Tickets</h3>
        {message && <p className="error-message">{message}</p>}

        {/* Open Tickets Section */}
        <div className="collapsible-section">
          <h4 onClick={() => setOpenVisible(!openVisible)} style={{ cursor: 'pointer' }}>
            {openVisible ? '▼ ' : '► '} Open Tickets
          </h4>
          {openVisible && (
            <div className="scrollable-table-container">
              <table className="ticket-table">
                {/* Table Headers with Sorting */}
                <thead>
                  <tr>
                    <th>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Ticket Number</span> {renderSortArrows('key')}
                      </div>
                    </th>
                    <th>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Summary</span> {renderSortArrows('summary')}
                      </div>
                    </th>
                    <th>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Reporter</span> {renderSortArrows('reporter')}
                      </div>
                    </th>
                    <th>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Status</span> {renderSortArrows('status')}
                      </div>
                    </th>
                    <th>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Created</span> {renderSortArrows('created')}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOpenIncidents.length > 0 ? (
                    filteredOpenIncidents.map((incident) => (
                      <tr key={incident.id}>
                        <td>{incident.key}</td>
                        <td>{incident.fields?.summary}</td>
                        <td>{incident.fields?.reporter?.displayName}</td>
                        <td>{incident.fields?.status?.name}</td>
                        <td>{new Date(incident.fields?.created).toLocaleDateString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No open incidents found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* Pagination Controls */}
              {/* Add your pagination controls here */}
            </div>
          )}
        </div>

        {/* Closed Tickets Section */}
        <div className="collapsible-section">
          <h4 onClick={() => setClosedVisible(!closedVisible)} style={{ cursor: 'pointer' }}>
            {closedVisible ? '▼ ' : '► '} Closed Tickets
          </h4>
          {closedVisible && (
            <div className="scrollable-table-container">
              <table className="ticket-table">
                {/* Table Headers with Sorting */}
                <thead>
                  <tr>
                    <th>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Ticket Number</span> {renderSortArrows('key')}
                      </div>
                    </th>
                    <th>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Summary</span> {renderSortArrows('summary')}
                      </div>
                    </th>
                    <th>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Reporter</span> {renderSortArrows('reporter')}
                      </div>
                    </th>
                    <th>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Status</span> {renderSortArrows('status')}
                      </div>
                    </th>
                    <th>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Created</span> {renderSortArrows('created')}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredClosedIncidents.length > 0 ? (
                    filteredClosedIncidents.map((incident) => (
                      <tr key={incident.id}>
                        <td>{incident.key}</td>
                        <td>{incident.fields?.summary}</td>
                        <td>{incident.fields?.reporter?.displayName}</td>
                        <td>{incident.fields?.status?.name}</td>
                        <td>{new Date(incident.fields?.created).toLocaleDateString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No closed incidents found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* Pagination Controls */}
              {/* Add your pagination controls here */}
            </div>
          )}
        </div>
      </div>

      {/* Render the New Request Popup if Visible */}
      {showNewRequest && (
        <NewRequest
          onClose={() => setShowNewRequest(false)}
          onSubmit={() => {
            setShowNewRequest(false);
            fetchIncidents();
          }}
        />
      )}
    </div>
  );
};

export default DeviceSupportTicket;
