import React from 'react';
import './Toggle.css';

const Toggle = ({ isOnline, setIsOnline }) => {
  return (
    <div className="toggle-container">
      <label className="switch">
        <input type="checkbox" checked={isOnline} onChange={() => setIsOnline(!isOnline)} />
        <span className="slider round"></span>
      </label>
      <span className="toggle-label">{isOnline ? 'Online' : 'Offline'}</span>
    </div>
  );
};

export default Toggle;
