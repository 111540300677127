// axiosInstance.js

import axios from 'axios';
import { getAuth } from 'firebase/auth';

// Initialize Firebase Auth
const auth = getAuth();

// Create an axios instance
const axiosInstance = axios.create({
    baseURL: 'https://project-download-upload.wn.r.appspot.com',
});

// Add a request interceptor to include the JWT
axiosInstance.interceptors.request.use(
    async (config) => {
        const currentUser = auth.currentUser;

        if (currentUser) {
            const token = await currentUser.getIdToken(/* forceRefresh */ true);
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
