import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './manageMedia.css';
import UploadMedia from './UploadMedia';
import previewIcon from '../content/previewIcon.svg';
import listIcon from '../content/listIcon.svg';
import directoryIcon from '../content/directoryIcon.svg';
import emptyDirectoryIcon from '../content/emptyDirectoryIcon.svg';

const ItemType = 'MEDIA';

const ManageMedia = ({ setSubHeader }) => {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isUploadOverlayVisible, setIsUploadOverlayVisible] = useState(false);
  const [activeButton, setActiveButton] = useState('image');
  const [currentIcon, setCurrentIcon] = useState(previewIcon);
  const [bucketName, setBucketName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [directories, setDirectories] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isFolderDropdownOpen, setIsFolderDropdownOpen] = useState(false);

  // New states for selected media and Edit form
  const [selectedMediaFile, setSelectedMediaFile] = useState(null);
  const [isEditOverlayVisible, setIsEditOverlayVisible] = useState(false);
  const [editTags, setEditTags] = useState([]);

  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    setSubHeader('Manage Media');
    if (currentUser) {
      fetchCustomerData();
    } else {
      console.error('User is not authenticated');
    }
  }, [setSubHeader, currentUser]);

  const fetchCustomerData = async () => {
    try {
      const token = await currentUser.getIdToken(true);
      const customerResponse = await axios.get(
        'https://project-download-upload.wn.r.appspot.com/api/customerLookup',
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const customerId = customerResponse.data.customer_id;
      setCustomerId(customerId);

      const bucketResponse = await axios.get(
        'https://project-download-upload.wn.r.appspot.com/api/customerUID',
        {
          params: { customer_id: customerId },
        }
      );

      const customerUID = bucketResponse.data.customer_uid;
      setBucketName(customerUID);

      fetchDirectories(customerId); // Use the new fetchDirectories API
      fetchMediaFiles(null);
    } catch (error) {
      console.error('Error fetching customer ID and bucket name:', error);
    }
  };

  const fetchDirectories = async (customerId) => {
    try {
      const token = await currentUser.getIdToken(true);

      const response = await axios.get(
        'https://project-download-upload.wn.r.appspot.com/api/media/fetchDirectories',
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { customer_id: customerId }, // Now passing customer_id directly
        }
      );

      const directoriesData = response.data.directories;
      setDirectories(directoriesData);
    } catch (error) {
      console.error('Error fetching directories:', error);
    }
  };

  const fetchMediaFiles = async (folderPath = null) => {
    setIsLoading(true);
    try {
      const token = await currentUser.getIdToken(true);
      const params = { type: activeButton };
      if (folderPath) {
        params.folder = folderPath;
      }
  
      const response = await axios.get(
        'https://project-download-upload.wn.r.appspot.com/api/media',
        {
          headers: { Authorization: `Bearer ${token}` },
          params,
        }
      );
  
      const mediaData = response.data;
      const mediaTypeDirectory = activeButton === 'image' ? 'Media/Image/' : 'Media/Video/';
  
      const mediaItems = [];
  
      for (const mediaFile of mediaData) {
        const fileName = `${mediaTypeDirectory}${mediaFile}`;
  
        console.log('Requesting signed URL for fileName:', fileName);
  
        const signedUrlResponse = await axios.post(
          'https://project-download-upload.wn.r.appspot.com/api/imagePreview-signed-url',
          { fileName },
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        mediaItems.push({ fileName, url: signedUrlResponse.data.signedUrl });
      }
  
      setMediaFiles(mediaItems);
    } catch (error) {
      console.error(`Error fetching ${activeButton}s:`, error);
    } finally {
      setIsLoading(false);
    }
  };  

  const moveMediaToFolder = async (fileUrl, folderTag) => {
    try {
      if (!fileUrl) {
        throw new Error("fileUrl is undefined or null.");
      }
  
      console.log('moveMediaToFolder called with:', { fileUrl, folderTag });
  
      const token = await currentUser.getIdToken(true);
      console.log('Token retrieved successfully:', token);
  
      // Extract the file path from the URL
      const fileName = decodeURIComponent(
        fileUrl
          .split('?')[0]
          .replace(`https://storage.googleapis.com/${bucketName}/`, '')
      );
      console.log('Extracted file name:', fileName);
  
      console.log('Preparing to tag file with folderTag:', folderTag);
  
      // Send request to the tagMediaFile API
      const response = await axios.post(
        'https://project-download-upload.wn.r.appspot.com/api/tagMediaFile',
        {
          fileName,
          folderTag,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      console.log('File tagged successfully:', response.data);
  
      // Refresh the media files
      fetchMediaFiles(selectedFolder);
      alert('File tagged successfully');
    } catch (error) {
      console.error('Error tagging file:', {
        message: error.message,
        stack: error.stack,
        config: error.config,
        response: error.response ? error.response.data : 'No response data',
      });
      alert('Failed to tag file. Please try again.');
    }
  };   

  const moveMedia = (dragIndex, hoverIndex) => {
    const draggedMedia = mediaFiles[dragIndex];
    const updatedMediaFiles = [...mediaFiles];
    updatedMediaFiles.splice(dragIndex, 1);
    updatedMediaFiles.splice(hoverIndex, 0, draggedMedia);
    setMediaFiles(updatedMediaFiles);
  };

  const renderMediaFiles = () => {
    if (mediaFiles.length === 0) {
      return <div className="manageMedia-no-media">No media files found</div>;
    }
    return mediaFiles
      .filter((item) => item.fileName.toLowerCase().includes(searchTerm.toLowerCase()))
      .map((item, index) => (
        <DraggableMedia
          key={item.fileName}
          fileName={item.fileName}
          fileUrl={item.url}
          index={index}
          moveMedia={moveMedia}
          moveMediaToFolder={moveMediaToFolder}
          activeButton={activeButton}
          directories={directories}
          selectedMediaFile={selectedMediaFile}
          setSelectedMediaFile={setSelectedMediaFile}
        />
      ));
  };  

  const handleImagesClick = () => {
    setActiveButton('image');
    setSelectedFolder(null);
    setSelectedMediaFile(null); // Deselect any selected media
    setIsEditOverlayVisible(false); // Close Edit overlay if open
    fetchDirectories(customerId); // Fetch directories using the new API
    fetchMediaFiles(null);
  };

  const handleVideosClick = () => {
    setActiveButton('video');
    setSelectedFolder(null);
    setSelectedMediaFile(null); // Deselect any selected media
    setIsEditOverlayVisible(false); // Close Edit overlay if open
    fetchDirectories(customerId); // Fetch directories using the new API
    fetchMediaFiles(null);
  };

  const handleUploadClick = () => {
    setIsUploadOverlayVisible(true);
    setIsEditOverlayVisible(false); // Close Edit overlay if open
  };

  const handleOverlayClose = () => {
    setIsUploadOverlayVisible(false);
  };

  const handleIconClick = () => {
    setCurrentIcon(currentIcon === previewIcon ? listIcon : previewIcon);
  };

  const handleFolderClick = (folder) => {
    const newSelectedFolder = selectedFolder ? `${selectedFolder}/${folder}` : folder;
    setSelectedFolder(newSelectedFolder);
    setSelectedMediaFile(null); // Deselect any selected media
    setIsEditOverlayVisible(false); // Close Edit overlay if open
    fetchDirectories(customerId); // Fetch directories using the new API
    fetchMediaFiles(newSelectedFolder);
  };

  const handleAddFolderClick = async () => {
    const folderName = prompt('Enter new folder name:');
    if (folderName && folderName.trim() !== '') {
      try {
        setIsLoading(true);
        const token = await currentUser.getIdToken(true);

        // Fetch customer_id
        const customerIdResponse = await axios.get('https://project-download-upload.wn.r.appspot.com/api/api/customerID', {
          headers: { Authorization: `Bearer ${token}` }
        });

        const customerId = customerIdResponse.data.customer_id;

        // Call the addDirectoryTag API
        await axios.post(
          'https://project-download-upload.wn.r.appspot.com/api/media/addDirectory',
          {
            newDirectoryTag: folderName,
            customerId: customerId
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        fetchDirectories(customerId); // Fetch directories after adding
      } catch (error) {
        console.error('Error adding folder tag:', error);
        alert('Failed to add folder tag. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderFolderDropdown = () => {
    // Only show the folder dropdown at the "home" level
    if (selectedFolder === null) {
      return (
        <div className="manageMedia-folder-dropdown">
          <div
            className="manageMedia-folder-dropdown-header"
            onClick={() => setIsFolderDropdownOpen(!isFolderDropdownOpen)}
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setIsFolderDropdownOpen(!isFolderDropdownOpen);
              }
            }}
          >
            <span className="manageMedia-folder-dropdown-title">Folder</span>
            <span
              className={`manageMedia-folder-dropdown-icon ${
                isFolderDropdownOpen ? 'open' : ''
              }`}
            >
              {isFolderDropdownOpen ? '▲' : '▼'}
            </span>
          </div>
          {isFolderDropdownOpen && (
            <div className="manageMedia-folder-dropdown-content">
              {renderFolders()}
            </div>
          )}
        </div>
      );
    }
    return null; // Do not render the dropdown at other levels
  };

  const handleBreadcrumbClick = (folderPath) => {
    setSelectedFolder(folderPath);
    setSelectedMediaFile(null); // Deselect any selected media
    setIsEditOverlayVisible(false); // Close Edit overlay if open
    fetchDirectories(customerId); // Fetch directories using the new API
    fetchMediaFiles(folderPath);
  };

  const renderBreadcrumbs = () => {
    if (!selectedFolder) {
      return null;
    }

    const pathSegments = selectedFolder.split('/').filter(Boolean); // Filter out empty segments

    return (
      <div className="manageMedia-breadcrumbs">
        <button
          className="manageMedia-breadcrumb"
          onClick={() => handleBreadcrumbClick(null)}
        >
          Home
        </button>
        {pathSegments.map((segment, index) => {
          const pathUpToSegment = pathSegments.slice(0, index + 1).join('/');
          return (
            <React.Fragment key={index}>
              <span className="manageMedia-breadcrumb-separator"> / </span>
              <button
                className="manageMedia-breadcrumb"
                onClick={() => handleBreadcrumbClick(pathUpToSegment)}
              >
                {segment}
              </button>
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const renderFolders = () => {
    return (
      <div className="manageMedia-folder-list">
        {directories.map((folder, index) => (
          <DroppableFolder
            key={index}
            folder={folder}
            moveMediaToFolder={moveMediaToFolder}
            handleFolderClick={handleFolderClick}
            isLoading={isLoading}
          />
        ))}

        {/* Only show "Add Folder" if no folder is selected (i.e., at the home level) */}
        {selectedFolder === null && (
          <div
            className="manageMedia-folder-icon-container"
            onClick={handleAddFolderClick}
            style={{ pointerEvents: isLoading ? 'none' : 'auto' }}
          >
            <img
              src={emptyDirectoryIcon}
              alt="Add Folder Icon"
              className="manageMedia-folder-icon"
            />
            <span className="manageMedia-folder-name">Add Folder</span>
          </div>
        )}
      </div>
    );
  };

  // Handler for the Delete button
  const handleDeleteClick = async () => {
    if (selectedMediaFile) {
      const confirmDelete = window.confirm('Are you sure you want to delete this media file?');
      if (confirmDelete) {
        try {
          const token = await currentUser.getIdToken(true);
  
          await axios.post(
            'https://project-download-upload.wn.r.appspot.com/api/deleteMediaFile',
            {
              fileName: selectedMediaFile.fileName,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );
  
          alert('Media file deleted successfully');
  
          // Remove the deleted file from the mediaFiles state
          setMediaFiles((prevMediaFiles) =>
            prevMediaFiles.filter((item) => item.fileName !== selectedMediaFile.fileName)
          );
  
          // Deselect the media file
          setSelectedMediaFile(null);
        } catch (error) {
          console.error('Error deleting media file:', error);
          alert('Failed to delete media file. Please try again.');
        }
      }
    }
  };  

  // Handler for the Edit button
  const handleEditClick = async () => {
    if (selectedMediaFile) {
      try {
        const token = await currentUser.getIdToken(true);
  
        const response = await axios.post(
          'https://project-download-upload.wn.r.appspot.com/api/getMediaTags',
          { fileName: selectedMediaFile.fileName },
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        const tags = response.data.tags || {};
        console.log('Fetched tags:', tags); // Log the fetched tags
  
        const tagsArray = Object.entries(tags).map(([key, value]) => ({ key, value }));
  
        setEditTags(tagsArray.length > 0 ? tagsArray : [{ key: '', value: '' }]);
        setIsEditOverlayVisible(true);
      } catch (error) {
        console.error('Error fetching media tags:', error);
        alert('Failed to fetch media tags. Please try again.');
      }
    }
  };  

  // Handlers for Edit Form
  const handleEditTagChange = (index, field, value) => {
    const updatedTags = [...editTags];
    updatedTags[index][field] = value;
    setEditTags(updatedTags);
  };

  const handleAddEditTag = () => {
    if (editTags.length < 10) {
      setEditTags([...editTags, { key: '', value: '' }]);
    }
  };

  const handleRemoveEditTag = (index) => {
    const updatedTags = [...editTags];
    updatedTags.splice(index, 1);
    setEditTags(updatedTags);
  };
  

  const handleEditCancel = () => {
    setIsEditOverlayVisible(false);
    setEditTags([]);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await currentUser.getIdToken(true);
  
      // Convert editTags array to object
      const metadata = {};
      editTags.forEach((tag) => {
        if (tag.key && tag.value) {
          metadata[tag.key] = tag.value;
        }
      });
  
      // Send the updated metadata to the server
      await axios.post(
        'https://project-download-upload.wn.r.appspot.com/api/updateMediaMetadata',
        {
          fileName: selectedMediaFile.fileName,
          metadata,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      alert('Media tags updated successfully');
  
      // Reset form
      setIsEditOverlayVisible(false);
      setEditTags([]);
    } catch (error) {
      console.error('Error updating media tags:', error);
      alert('Failed to update media tags. Please try again.');
    }
  };
  

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="manageMedia-content-manager-container">
        <div className="manageMedia-content-manager">
          <div className="manageMedia-content-manager-buttons">
            <button
              onClick={handleImagesClick}
              className={activeButton === 'image' ? 'active' : ''}
              disabled={isLoading}
            >
              Images
            </button>
            <button
              onClick={handleVideosClick}
              className={activeButton === 'video' ? 'active' : ''}
              disabled={isLoading}
            >
              Videos
            </button>
            <input
              type="text"
              className="manageMedia-search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search media..."
              disabled={isLoading}
            />
            <button
              onClick={handleUploadClick}
              className="manageMedia-upload-button"
              disabled={isLoading}
            >
              Upload
            </button>
            {/* Conditionally render the Edit button */}
            {selectedMediaFile && (
              <>
                <button
                  type="button"
                  onClick={handleEditClick}
                  className="manageMedia-edit-button"
                  disabled={isLoading}
                >
                  Edit
                </button>
                <button
                  type="button"
                  onClick={handleDeleteClick}
                  className="manageMedia-delete-button-red" // Updated class name
                  disabled={isLoading}
                >
                  Delete
                </button>
              </>
            )}
            <button
              onClick={handleIconClick}
              className="manageMedia-icon-button"
              disabled={isLoading}
            >
              <img src={currentIcon} alt="Current Icon" className="manageMedia-icon-image" />
            </button>
          </div>

          {renderBreadcrumbs()}

          {renderFolderDropdown()}

          <div className="manageMedia-media-display-grid">
            {isLoading ? (
              <div className="manageMedia-loading-indicator-within-grid">
                <div className="spinner"></div>
                <p>Loading media files...</p>
              </div>
            ) : (
              renderMediaFiles()
            )}
          </div>

          {/* Upload Overlay */}
          {isUploadOverlayVisible && (
            <div className="manageMedia-upload-overlay">
              <div className="manageMedia-upload-overlay-content">
                <button className="manageMedia-close-overlay-button" onClick={handleOverlayClose}>
                  X
                </button>
                <UploadMedia email={currentUser?.email} setSubHeader={() => {}} />
              </div>
            </div>
          )}

          {/* Edit Overlay */}
          {isEditOverlayVisible && (
            <div className="manageMedia-edit-overlay">
              <div className="manageMedia-edit-overlay-content">
                <button className="manageMedia-close-overlay-button" onClick={handleEditCancel}>
                  X
                </button>
                <h2>Edit Media</h2>
                <div className="manageMedia-edit-media-display">
                  {activeButton === 'image' ? (
                    <img
                      src={selectedMediaFile.fileUrl} // Use fileUrl here
                      alt="Selected Media"
                      className="manageMedia-edit-media-file"
                    />
                  ) : (
                    <video
                      src={selectedMediaFile.fileUrl} // Use fileUrl here
                      className="manageMedia-edit-media-file"
                      controls
                    />
                  )}
                </div>
                <form onSubmit={handleEditSubmit} className="manageMedia-edit-form">
                  <div className="manageMedia-edit-tags">
                    {editTags.map((tag, index) => (
                      <div key={index} className="manageMedia-edit-tag">
                        <input
                          type="text"
                          placeholder="Key"
                          value={tag.key}
                          onChange={(e) => handleEditTagChange(index, 'key', e.target.value)}
                          className="manageMedia-edit-tag-input"
                          required
                        />
                        <input
                          type="text"
                          placeholder="Value"
                          value={tag.value}
                          onChange={(e) => handleEditTagChange(index, 'value', e.target.value)}
                          className="manageMedia-edit-tag-input"
                          required
                        />
                        <button
                          type="button"
                          onClick={() => handleRemoveEditTag(index)}
                          className="manageMedia-edit-remove-tag-button"
                        >
                          ×
                        </button>
                      </div>
                    ))}
                  </div>
                  {editTags.length < 10 && (
                    <button type="button" onClick={handleAddEditTag} className="manageMedia-edit-add-tag-button">
                      Add Tag
                    </button>
                  )}
                  <div className="manageMedia-edit-actions">
                    <button type="button" onClick={handleEditCancel} className="manageMedia-edit-cancel-button">
                      Cancel
                    </button>
                    <button type="submit" className="manageMedia-edit-submit-button">
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {/* Video Modal */}
          {selectedVideo && (
            <div className="manageMedia-video-modal">
              <div className="manageMedia-video-content">
                <button onClick={() => setSelectedVideo(null)}>Close</button>
                <video src={selectedVideo} controls autoPlay />
              </div>
            </div>
          )}
        </div>
      </div>
    </DndProvider>
  );  
};

// DraggableMedia component for drag-and-drop
const DraggableMedia = ({
  fileName,
  fileUrl,
  index,
  moveMedia,
  moveMediaToFolder,
  activeButton,
  directories,
  selectedMediaFile,
  setSelectedMediaFile,
}) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index, fileName, fileUrl },  // Add fileUrl here
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      const dragIndex = draggedItem.index;
      const hoverIndex = index;
      if (dragIndex !== hoverIndex) {
        moveMedia(dragIndex, hoverIndex);
        draggedItem.index = hoverIndex;
      }
    },
    drop: (draggedItem, monitor) => {
      if (monitor.isOver() && monitor.canDrop()) {
        const targetFolder = monitor.getItem();
        if (directories.includes(targetFolder)) {
          moveMediaToFolder(draggedItem.fileUrl, targetFolder);  // Use fileUrl here
        }
      }
    },
  });

  // Handle click to select/deselect media file
  const handleClick = (e) => {
    e.stopPropagation(); // Prevent triggering drag/drop
    if (selectedMediaFile && selectedMediaFile.fileName === fileName) {
      setSelectedMediaFile(null); // Deselect if already selected
    } else {
      setSelectedMediaFile({ fileName, fileUrl }); // Select the new file
    }
  };

  return (
    <div
      ref={(node) => ref(drop(node))}
      className={`manageMedia-media-item ${
        selectedMediaFile && selectedMediaFile.fileName === fileName ? 'selected' : ''
      }`}
      onClick={handleClick}
    >
      {activeButton === 'image' ? (
        <img src={fileUrl} alt="Media" className="manageMedia-media-file" />
      ) : (
        <video src={fileUrl} className="manageMedia-media-file" controls />
      )}
    </div>
  );
};

// DroppableFolder component to accept media items dropped into it and handle folder click
const DroppableFolder = ({ folder, moveMediaToFolder, handleFolderClick, isLoading }) => {
  const [, drop] = useDrop({
    accept: ItemType,
    drop: (draggedItem) => {
      console.log('Dropped item:', draggedItem);  // Log to verify draggedItem includes fileUrl
      moveMediaToFolder(draggedItem.fileUrl, folder);  // Use fileUrl from draggedItem
    },
  });

  return (
    <div
      ref={drop}
      className="manageMedia-folder-icon-container"
      onClick={() => handleFolderClick(folder)} // Handle folder click here
      style={{ pointerEvents: isLoading ? 'none' : 'auto' }}
    >
      <img src={directoryIcon} alt="Folder Icon" className="manageMedia-folder-icon" />
      <span className="manageMedia-folder-name">{folder}</span>
    </div>
  );
};


export default ManageMedia;
