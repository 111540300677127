import React, { useState, useEffect } from 'react';
import './MediaManagement.css';
import UploadMedia from './MediaManagement/UploadMedia';
import ContentManager from './MediaManagement/ContentManager';
import ManageMedia from './MediaManagement/manageMedia';

const MediaManagement = () => {
  const [activeSection, setActiveSection] = useState('mediaInfo');
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Try to get email from JWT token stored in localStorage
    const user = JSON.parse(localStorage.getItem('user')); // Assuming user data is stored in localStorage
    const jwtEmail = user ? user.email : '';

    if (jwtEmail) {
      setEmail(jwtEmail);
    } else {
      // Fallback to getting email from cookie
      const emailCookie = document.cookie.split('; ').find(row => row.startsWith('userEmail='));
      const userEmail = emailCookie ? emailCookie.split('=')[1] : '';
      
      if (userEmail) {
        setEmail(userEmail);
      } else {
        console.error('Email not found in JWT or cookie');
      }
    }
  }, []);

  const renderSection = () => {
    switch (activeSection) {
      case 'mediaInfo':
        return <div className="media-management-section-content">Media Info Section - Coming Soon</div>;
      case 'uploadMedia':
        return <UploadMedia email={email} setSubHeader={setSubHeader} />; // Pass the setSubHeader function
      case 'contentManager':
        return <ContentManager setSubHeader={setSubHeader} />; // Pass the setSubHeader function
      case 'manageMedia':
        return <ManageMedia setSubHeader={setSubHeader} />; // Pass the setSubHeader function
      default:
        return <div className="media-management-section-content">Select a section</div>;
    }
  };

  const [subHeader, setSubHeader] = useState('Media Management');

  useEffect(() => {
    switch (activeSection) {
      case 'uploadMedia':
        setSubHeader('Upload Media');
        break;
      case 'contentManager':
        setSubHeader('Content Manager');
        break;
      case 'manageMedia':
        setSubHeader('Manage Media');
        break;
      default:
        setSubHeader('Media Management');
    }
  }, [activeSection]);

  return (
    <div className="media-management-container">
      <div className="sidebar">
        <ul>
          <li className={activeSection === 'mediaInfo' ? 'active' : ''} onClick={() => setActiveSection('mediaInfo')}>
            Media Info
          </li>
          <li className={activeSection === 'contentManager' ? 'active' : ''} onClick={() => setActiveSection('contentManager')}>
            Content Manager
          </li>
          <li className={activeSection === 'manageMedia' ? 'active' : ''} onClick={() => setActiveSection('manageMedia')}>
            Manage Media
          </li>
        </ul>
      </div>
      <div className="main-content">
        {renderSection()}
      </div>
    </div>
  );
};

export default MediaManagement;
