// src/components/CollapsibleSection.js
import React from 'react';
import PropTypes from 'prop-types';
import './CollapsibleSection.css'; // Ensure this CSS file exists for specific styles

const CollapsibleSection = ({ title, isOpen, toggleOpen, selectedOption, children }) => {
  return (
    <div className="collapsiblesection-section">
      <div
        className="collapsiblesection-header"
        onClick={toggleOpen}
        aria-expanded={isOpen}
      >
        <h4 className="collapsiblesection-title">
          {title}
          {selectedOption ? `: ${selectedOption}` : ''}
        </h4>
        <button
          className="collapsiblesection-toggle-button"
          aria-label={isOpen ? 'Collapse' : 'Expand'}
        >
          {isOpen ? '−' : '+'}
        </button>
      </div>
      {isOpen && (
        <div className="collapsiblesection-content">
          {children}
        </div>
      )}
    </div>
  );
};

CollapsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  selectedOption: PropTypes.string, // Optional prop
  children: PropTypes.node.isRequired,
};

CollapsibleSection.defaultProps = {
  selectedOption: '',
};

export default CollapsibleSection;
