// SecurityCompliance.js
import React, { useState } from 'react';
import EncryptionSettings from './EncryptionSettings';
import './SecurityCompliance.css';

const SecurityCompliance = () => {
  const [activeTab, setActiveTab] = useState('general');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'general':
        return <div>General Security & Compliance Information</div>;
      case 'encryption':
        return <EncryptionSettings />;
      default:
        return <div>Select a tab</div>;
    }
  };

  return (
    <div className="security-compliance-container">
      <div className="tabs">
        <button onClick={() => setActiveTab('general')} className={activeTab === 'general' ? 'active' : ''}>
          General
        </button>
        <button onClick={() => setActiveTab('encryption')} className={activeTab === 'encryption' ? 'active' : ''}>
          Encryption
        </button>
      </div>
      <div className="tab-content">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default SecurityCompliance;
