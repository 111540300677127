// src/components/ConfigureDeviceForm.js

import React from 'react';
import Toggle from './Toggle'; // Ensure correct path
import './ConfigureDeviceForm.css'; // Ensure correct path

const ConfigureDeviceForm = ({
  isOnline,
  setIsOnline,
  game,
  games,
  setGame,
  minBet,
  setMinBet,
  maxBet,
  setMaxBet,
  submittedMinBet,
  submittedMaxBet,
  handleSubmit
}) => {
  return (
    <div className="configure-device-form">
      <h2>Configure Device</h2>
      <form onSubmit={handleSubmit}>
        {/* Toggle for Online/Offline */}
        <Toggle isOnline={isOnline} setIsOnline={setIsOnline} />

        {/* Conditional Fields Based on Toggle */}
        {isOnline && (
          <>
            {/* Game Selection */}
            <div className="input-group">
              <div className="label-group">
                <label htmlFor="game">Game:</label>
              </div>
              <select
                id="game"
                value={game}
                onChange={(e) => setGame(e.target.value)}
                required
              >
                <option value="">Select Game</option>
                {games.map((gameItem, index) => (
                  <option key={index} value={gameItem.game_name}>
                    {gameItem.game_name}
                  </option>
                ))}
              </select>
            </div>

            {/* Min Bet */}
            <div className="input-group">
              <div className="label-group">
                <label htmlFor="minBet">Min Bet:</label>
                <span className="current-value">Current: ${submittedMinBet}</span>
              </div>
              <input
                type="text"
                id="minBet"
                value={minBet}
                onChange={(e) => setMinBet(e.target.value)}
                pattern="\d*"
                title="Please enter a whole number"
                placeholder="Enter Min Bet"
                required
              />
            </div>

            {/* Max Bet */}
            <div className="input-group">
              <div className="label-group">
                <label htmlFor="maxBet">Max Bet:</label>
                <span className="current-value">Current: ${submittedMaxBet}</span>
              </div>
              <input
                type="text"
                id="maxBet"
                value={maxBet}
                onChange={(e) => setMaxBet(e.target.value)}
                pattern="\d*"
                title="Please enter a whole number"
                placeholder="Enter Max Bet"
                required
              />
            </div>
          </>
        )}

        {/* Submit Button */}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ConfigureDeviceForm;
