import React from 'react';
import './ImagePreview.css';

const ImagePreview = ({ fileUrl, onClose }) => {
  console.log('Rendering ImagePreview with URL:', fileUrl);

  return (
    <div className="image-preview-overlay">
      <div className="image-preview-content">
        <button className="close-preview-button" onClick={onClose}>X</button>
        {fileUrl ? (
          <img src={fileUrl} alt="Preview" className="image-preview" />
        ) : (
          <p>Loading image...</p>
        )}
      </div>
    </div>
  );
};

export default ImagePreview;
