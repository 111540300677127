// ModifyUser.js
import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import './ModifyUser.css';
import CreateUser from './CreateUser';

const ModifyUser = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [availableRoles, setAvailableRoles] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserRoles, setSelectedUserRoles] = useState([]);
  const [initialAvailableRoles, setInitialAvailableRoles] = useState([]);
  const [showRoleOverlay, setShowRoleOverlay] = useState(false);
  const [showCreateUserOverlay, setShowCreateUserOverlay] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (currentUser) {
      fetchUsers();
      fetchRoles();
    }
  }, [currentUser]);

  const handleErrorResponse = (response) => {
    if (response.status === 401) {
      setError('Unauthorized access. Please log in again.');
    } else if (response.status === 403) {
      setError(
        'Forbidden access. You do not have permission to perform this action.'
      );
    } else {
      setError('An error occurred. Please try again later.');
    }
  };

  const fetchWithAuth = async (url, options = {}) => {
    const token = await currentUser.getIdToken();
    const defaultHeaders = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    const response = await fetch(url, {
      ...options,
      headers: {
        ...defaultHeaders,
        ...options.headers,
      },
    });
    if (!response.ok) {
      handleErrorResponse(response);
      throw new Error('Network response was not ok');
    }
    return response.json();
  };

  const fetchUsers = async () => {
    try {
      const token = await currentUser.getIdToken();
      const response = await fetch(
        `https://project-download-upload.wn.r.appspot.com/api/users`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        handleErrorResponse(response);
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchRoles = async () => {
    try {
      const data = await fetchWithAuth(
        'https://project-download-upload.wn.r.appspot.com/roles'
      );
      setAvailableRoles(data);
      setInitialAvailableRoles(data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleRoleSelection = (role) => {
    if (selectedUserRoles.includes(role)) {
      setSelectedUserRoles(selectedUserRoles.filter((r) => r !== role));
      setAvailableRoles([...availableRoles, role]);
    } else {
      setSelectedUserRoles([...selectedUserRoles, role]);
      setAvailableRoles(availableRoles.filter((r) => r !== role));
    }
  };

  const handleNewUserClick = () => {
    setShowCreateUserOverlay(true);
  };

  const handleCloseCreateUserOverlay = () => {
    setShowCreateUserOverlay(false);
    fetchUsers();
  };

  const handleRolesClick = async (user) => {
    setSelectedUser(user);

    const userRoles = user.roles || [];
    setSelectedUserRoles(userRoles);
    setAvailableRoles(
      initialAvailableRoles.filter((role) => !userRoles.includes(role))
    );
    setShowRoleOverlay(true);

    try {
      const data = await fetchWithAuth(
        `https://project-download-upload.wn.r.appspot.com/user-roles?email=${user.email}`
      );
      setSelectedUserRoles(data.roles || []);
      setAvailableRoles(
        initialAvailableRoles.filter(
          (role) => !(data.roles || []).includes(role)
        )
      );
    } catch (error) {
      console.error('Error fetching user roles:', error);
    }
  };

  const handleRoleSubmit = async () => {
    try {
      await fetchWithAuth(
        'https://project-download-upload.wn.r.appspot.com/update-user-roles',
        {
          method: 'POST',
          body: JSON.stringify({
            email: selectedUser.email,
            roles: selectedUserRoles,
          }),
        }
      );

      setSuccess(`Roles updated successfully for ${selectedUser.email}`);
      setShowSuccess(true);
      setError('');
      setShowRoleOverlay(false);
      fetchUsers();
    } catch (error) {
      setError('Failed to update roles: ' + error.message);
      setSuccess('');
    }
  };

  const handleRemoveUser = async (userEmail) => {
    try {
      await fetchWithAuth(
        'https://project-download-upload.wn.r.appspot.com/removeUser',
        {
          method: 'DELETE',
          body: JSON.stringify({ email: userEmail }),
        }
      );

      setSuccess('User removed successfully');
      setShowSuccess(true);
      setError('');
      fetchUsers();
    } catch (error) {
      setError('Failed to remove user: ' + error.message);
      setSuccess('');
    }
  };

  return (
    <div className="modify-user">
      <input
        type="text"
        placeholder="Search users by email"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="modify-user-search-box"
      />
      <button onClick={handleNewUserClick} className="new-user-button">
        Create User
      </button>
      <ul className="modify-user-list">
        {users
          .filter((user) => user.email.includes(searchTerm))
          .map((user) => (
            <li key={user.uid} className="modify-user-list-item">
              {user.email}
              <div className="modify-user-button-container">
                <button
                  onClick={() => handleRolesClick(user)}
                  className="modify-user-roles-button"
                >
                  Roles
                </button>
                <button
                  onClick={() => handleRemoveUser(user.email)}
                  className="modify-user-remove-button"
                >
                  Remove
                </button>
              </div>
            </li>
          ))}
      </ul>
      {showRoleOverlay && (
        <div className="modify-user-overlay">
          <div className="modify-user-overlay-content">
            <h2>Select Roles for {selectedUser.email}</h2>
            <div className="modify-user-role-selection">
              <div className="modify-user-roles-container">
                <div className="modify-user-available-roles">
                  <h3>Available Roles</h3>
                  <ul className="modify-user-roles-list">
                    {availableRoles.map((role) => (
                      <li key={role}>
                        <button
                          type="button"
                          onClick={() => handleRoleSelection(role)}
                          className="modify-user-role-button"
                        >
                          {role}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="modify-user-selected-roles">
                  <h3>Selected Roles</h3>
                  <ul className="modify-user-roles-list">
                    {selectedUserRoles.map((role) => (
                      <li key={role}>
                        <button
                          type="button"
                          onClick={() => handleRoleSelection(role)}
                          className="modify-user-role-button"
                        >
                          {role}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <button
              onClick={handleRoleSubmit}
              className="modify-user-submit-button"
            >
              Submit
            </button>
            <button
              onClick={() => setShowRoleOverlay(false)}
              className="modify-user-cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {showCreateUserOverlay && (
        <div className="modify-user-overlay">
          <div className="modify-user-overlay-content">
            <div className="modify-user-overlay-header">
              <h2>Create User</h2>
              {/*}
              <button
                onClick={handleCloseCreateUserOverlay}
                className="modify-user-close-button"
              >
                X
              </button>
              */}
            </div>
            <CreateUser onClose={handleCloseCreateUserOverlay} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ModifyUser;
