// AvailableFilesList.js
import React from 'react';
import imageIcon from '../content/imageIcon.svg';

const AvailableFilesList = ({
  isTableSelected,
  filteredAvailableFiles,
  handleAddFile,
  handlePreviewClick,
}) => {
  return (
    <div className="content-manager-column available-files">
      <h3>Available Files</h3>
      <ul className="option-list">
        {isTableSelected &&
          filteredAvailableFiles
            .filter((fileObj) => fileObj.file && fileObj.file.trim() !== '')
            .map((fileObj) => (
              <li
                key={fileObj.file}
                className="option-item"
                onClick={() => handleAddFile(fileObj)}
              >
                <img
                  src={imageIcon}
                  alt="Preview Icon"
                  className="preview-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePreviewClick(fileObj.file);
                  }}
                />
                {fileObj.friendlyName !== 'Unknown' ? fileObj.friendlyName : 'Unknown'}
              </li>
            ))}
      </ul>
    </div>
  );
};

export default AvailableFilesList;
